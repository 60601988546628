import React, { useState } from 'react';
import ExaminerTier from './ExaminerTier';

import { PAY_AS_YOU_GO_PRICES, PER_EXAMINER_PRO_PRICES } from './prices';

import TeamsTier from './TeamsTier';
import ExaminerPAYGTier from './ExaminerPAYGTier';

const ExaminerPAYGPricingTier = ({ selectedPlan, setSelectedPlan }) => {
  const [numberOfExaminers, setNumberOfExaminers] = useState(PER_EXAMINER_PRO_PRICES[0].id);

  return (
    <ExaminerPAYGTier
      selectedPlan={selectedPlan}
      setSelectedPlan={setSelectedPlan}
      // numberOfExaminers={numberOfExaminers}
      // setNumberOfExaminers={setNumberOfExaminers}
      perUsePrices={PAY_AS_YOU_GO_PRICES}
    />
  );
};

export default ExaminerPAYGPricingTier;
