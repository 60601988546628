import React from 'react';
import { FaRegClock, FaCalculator } from 'react-icons/fa';
import { IconContext } from 'react-icons';

const AgeCalculatorIcon = ({ showIconText, ...rest }) => (
  <IconContext.Provider value={{ size: '70' }}>
    <div
      {...rest}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '140px',
        height: '140px',
      }}
    >
      <FaRegClock />
      {/* <FaCalculator style={{ position: 'absolute', top: '60px', left: '35px' }} /> */}
      {showIconText && (
        <div style={{ marginTop: '10px', fontSize: '12px', fontWeight: 'bold' }}>
          Age Calculator
        </div>
      )}
    </div>
  </IconContext.Provider>
);

export default AgeCalculatorIcon;

export const AgeCalculatorIconSmall = ({ showIconText, ...rest }) => (
  <IconContext.Provider value={{ size: '70' }}>
    <div
      {...rest}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        // width: '140px',
        // height: '140px',
      }}
    >
      <FaRegClock />
      {/* <FaCalculator style={{ position: 'absolute', top: '60px', left: '35px' }} /> */}
      {showIconText && (
        <div style={{ marginTop: '10px', fontSize: '12px', fontWeight: 'bold' }}>
          Age Calculator
        </div>
      )}
    </div>
  </IconContext.Provider>
);

export const FileAudioIconSmall = ({ showIconText, ...rest }) => (
  <IconContext.Provider value={{ size: '70' }}>
    <div
      {...rest}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        // width: '140px',
        // height: '140px',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M4 22h14a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v2" />
        <path d="M14 2v4a2 2 0 0 0 2 2h4" />
        <circle cx="3" cy="17" r="1" />
        <path d="M2 17v-3a4 4 0 0 1 8 0v3" />
        <circle cx="9" cy="17" r="1" />
      </svg>
    </div>
  </IconContext.Provider>
);

export const LibraryIconSmall = ({ showIconText, ...rest }) => (
  <IconContext.Provider value={{ size: '70' }}>
    <div
      {...rest}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        // width: '140px',
        // height: '140px',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="m16 6 4 14" />
        <path d="M12 6v14" />
        <path d="M8 8v12" />
        <path d="M4 4v16" />
      </svg>
    </div>
  </IconContext.Provider>
);

export const FormsIconSmall = ({ showIconText, ...rest }) => (
  <IconContext.Provider value={{ size: '70' }}>
    <div
      {...rest}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        // width: '140px',
        // height: '140px',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="m3 3 3 9-3 9 19-9Z" />
        <path d="M6 12h16" />
      </svg>{' '}
    </div>
  </IconContext.Provider>
);

export const EvaluationIconSmall = ({ showIconText, ...rest }) => (
  <IconContext.Provider value={{ size: '70' }}>
    <div
      {...rest}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        // width: '140px',
        // height: '140px',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z" />
        <path d="M12 5 9.04 7.96a2.17 2.17 0 0 0 0 3.08c.82.82 2.13.85 3 .07l2.07-1.9a2.82 2.82 0 0 1 3.79 0l2.96 2.66" />
        <path d="m18 15-2-2" />
        <path d="m15 18-2-2" />
      </svg>
    </div>
  </IconContext.Provider>
);
