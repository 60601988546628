import React from 'react';
import { Lead, Headline } from '../../components';

const SectionHeader = ({ headline = 'Plans & Pricing' }: any) => (
  <div className="sm:text-center">
    <Headline el="h2">{headline}</Headline>
    <div className="mt-5">
      <Lead>
        Complete flexibility in pricing and use for every SLP and Team.{' '}
        <br className="hidden md:block" />
        Try out an individual tool, get unlimited access to everything with SLP Pro, or enroll your
        entire team.
      </Lead>
    </div>
  </div>
);

export default SectionHeader;
