import React from 'react';
import { graphql } from 'gatsby';
import { SEO, SitewideFooter, SitewideHeader } from '../components';
import { CheckCircleIcon, ShareIcon, BellIcon } from '@heroicons/react/outline';
import {
  FaClipboardCheck,
  FaSync,
  FaFileAlt,
  FaMagic,
  FaFileInvoice,
  FaAlignJustify,
  FaRocket,
  FaUpload,
  FaBriefcase,
  FaToggleOn,
  FaTasks,
  FaCalendarAlt,
} from 'react-icons/fa';
import { AiOutlineForm, AiOutlineFileSync } from 'react-icons/ai';
import { IoMdPaper } from 'react-icons/io';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';

import {
  AlternatingFeatureBlock,
  SplitGridLogoCloud,
  TestimonialsCloud,
  SimpleCenteredHeading,
  CenteredHero,
} from '../components/landing';
import BgReportPic from '../../static/images/landing/bg-report.png';
import BgTemplatePic from '../../static/images/landing/UniversalTemplate.png';
import AssessmentImpressions from '../../static/images/landing/AssessmentImpressions.png';
import GatheringInfoStep from '../../static/images/landing/GatheringInfoStep.png';
import ShareForm from '../../content/assets/home/forms/ShareFormInSpanish.png';
import HipaaLogo from '../../content/assets/logos/hipaa/hipaa.inline.svg';
import FerpaLogo from '../../content/assets/logos/ed/ferpa-logo.png';
import WhoIsItFor from '../components/landing/CenteredHero/WhoIsItFor';
import LandingCTA from '../components/landing/LandingCTA';
import { HomeFooterCTA } from '../components/landing/FooterCTA';

const ViewfinderCircleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fill="currentColor"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.5 3.75H6A2.25 2.25 0 003.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0120.25 6v1.5m0 9V18A2.25 2.25 0 0118 20.25h-1.5m-9 0H6A2.25 2.25 0 013.75 18v-1.5M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    />
  </svg>
);

const PRODUCT = {
  features: [
    {
      title:
        'Organize not only the information in your current report, but for every report you write in the future.',
      // description:
      //   'Organize not only the information in your current report, but for every report you write in the future.',
      illustration: {
        // webp: DragDropIllustration,
        png: GatheringInfoStep,
        alt: 'An overview of an evaluation in progress from Evalubox',
      },
      bullets: [
        {
          name: 'Testing Impressions',
          description:
            'Easily capture your testing impressions while they are fresh in your mind and have it automatically populated into your report.',
          icon: FaClipboardCheck,
        },
        {
          name: 'Digital Intake forms',
          description:
            'Our intake forms allow you to collect only the pertinent information you need from parents, teachers, and other professionals. You enter it once and it inserts it into the template in the correct places.',
          icon: AiOutlineForm,
        },
        {
          name: 'Smart templates',
          description:
            'Use your existing template or select one of our pre-designed templates to automatically populate your report with your intake and testing data with one click.',
          icon: AiOutlineFileSync,
        },
      ],
    },
  ],
};

const TEMPLATE = {
  features: [
    {
      // title:
      //   'Meet the templates of the future',
      description: `Turn your commonly used documents (word, google docs) - such as evaluation reports, templates, blurbs -- into smart templates.`,
      // 'Fully customizable templates with pre-programmed fields and a “blurb library” to quickly add your most precious and favorite paragraphs.',
      illustration: {
        // webp: DragDropIllustration,
        png: BgTemplatePic,
        alt: 'A smart template from Evalubox',
      },
      bullets: [
        {
          name: 'Create a smart template document',
          description:
            'Start a new document or use one of our built in templates we have created after 20+ years of writing evaluations - The Universal Template which has been field tested in 54 districts. It’s accurate, concise, and beautifully describes communication. It’s yours to use inside Evalubox.',
          // 'Field tested in 54 districts, the Universal Template gives you 20+ years of evaluation writing experience you can use to create a report.',
          icon: FaRocket,
        },
        {
          name: 'Add form fields in document',
          description: `Smart templates gives you a place to where you link all the form fields to a document, so that when someone fills out form or questionnaire, that content will be replaced in the document. 
            This gives you a way to personalize every report without the need to find/replace names or pronouns, copy info from other documents/pdf into your report.
            `,
          icon: FaUpload,
        },
        {
          name: 'Create report narrative from a template',
          description:
            'When a you (parent, teacher, nurse) enters data through a questionnaire or form, Evalubox will automatically place the data into a document without needing to replicate, copy/paste, find/replace from multiple sources. Giving you a personalized narrative for every evaluation.',
          icon: FaToggleOn,
        },
      ],
    },
  ],
};

const VALUE_PROPS = {
  features: [
    {
      title: '1. Quickly Gather Background Information',
      description: `Never has there been a faster, more accurate way to get the information you need to complete your report on time. 
        <br /> <br />
        Parents, teachers, and nurses are texted or emailed a link. They fill out a questionnaire, and their responses are incorporated directly into your report.`,
      illustration: {
        // webp: DragDropIllustration,
        png: ShareForm,
        alt: 'Video showing how to Quickly Gather Background Information',
        src: 'https://cdn.app.evalubox.com/marketing-site/evaluations/step-1/Evalubox+Step+1+Referral+Questionnaires.mp4',
        cover:
          'https://cdn.app.evalubox.com/marketing-site/evaluations/step-1/Evalubox+step+1+Referral+Questionnaires+Thumbnail.png',
      },
      illustrationIsVideo: true,
      bullets: [],
    },
    {
      title: '2. Efficiently Organize and Enter Your Testing Results',
      description: `Evalubox has a step-by-step process to organize the results of standardized testing into tables, enter your diagnostic impressions, and choose goals. 
        <br /> <br />
        Even for communication areas that were not tested, Evalubox generates text explaining that there was no initial concern. Every area is addressed.`,
      illustration: {
        // webp: DragDropIllustration,
        png: AssessmentImpressions,
        alt: 'Video showing how to Efficiently Organize and Enter Your Testing Results',
        src: 'https://cdn.app.evalubox.com/marketing-site/evaluations/step-2/Evalubox+step+2+assessment+impressions.mp4',
        cover:
          'https://cdn.app.evalubox.com/marketing-site/evaluations/step-2/Evalubox+Step+2+Assessment+Impressions+Thumbnail.png',
      },
      illustrationIsVideo: true,
      bullets: [],
    },
    {
      title: `3. Create Your Report`,
      description: `Background information and assessment impressions are automatically integrated into report templates with the click of a button. 
        <br /> <br />
        Flexibility is key! Use our universal template or add your own. Then, add disorder-specific text from our assessment paragraph library.
        `,
      illustration: {
        // webp: DragDropIllustration,
        png: BgReportPic,
        alt: 'Video showing how to create Your Report',
        src: 'https://cdn.app.evalubox.com/marketing-site/evaluations/step-3/Evalubox+step+3+report+generation.mp4',
        cover:
          'https://cdn.app.evalubox.com/marketing-site/evaluations/step-3/Evalubox+step+3+Report+Generation+Thumbnail.png',
      },
      illustrationIsVideo: true,
      bullets: [],
    },
  ],
};

const SOLUTIONS = {
  features: [
    {
      title: 'Massively reduce your report writing time',
      description:
        'Is your doctor or any other medical professional sitting down to write for 3-8 hours after they see you? Not possible. Then why are you?',
      bullets: [
        {
          name: 'Enter information only once',
          description: `Make digital intake forms work for you by feeding information directly into your report without copying and pasting.`,
          icon: FaSync,
        },
        {
          name: 'Create your report soon after testing',
          description:
            'As soon as your referral and testing results are entered into Evalubox you are pretty much done. Just enter the communication-specific information and your favorite paragraphs and it is ready to go.',
          icon: IoMdPaper,
        },
        {
          name: 'Customize in an editable document',
          description: `Edit, save, and export your evaluation reports online without downloading them into word. You can seamlessly add intake information, tests scores, goals, recommendations, templates, and blurbs directly into your report, saving you time. You can also conduct peer reviews, ensuring accuracy before exporting your report as a pdf to add to your organization's system.`,
          icon: FaFileAlt,
        },
      ],
    },
    {
      title: 'Error-Free Speech Language Reports',
      description: 'Gone are the days of needless mistakes.',
      bullets: [
        {
          name: 'Never worry about having the wrong name or pronoun in your report again',
          description: `Choose the name and pronouns once and they are automatically placed throughout the report.`,
          icon: FaMagic,
        },
        {
          name: 'No more forgetting required report pieces',
          description: `Templates are laid out with all essential school or clinic components so that the final draft meets all insurance and IEP requirements.`,
          icon: FaFileInvoice,
        },
        {
          name: 'Align the results of your impressions, diagnosis, summary, and goals',
          description:
            'Even the best reports are called into question when the testing, what is said in the summary, and goals don’t match up. Have those fields populate automatically based on your diagnostic decision.',
          icon: FaAlignJustify,
        },
        // {
        //   name: 'Only show what’s relevent',
        //   description:
        //     'Every evaluation is unique. Use conditionals to make your blurbs, test scores, client or district specific statements go directly into your report and have it only show up when applicable.',
        //   icon: PresentationChartLineIcon,
        // },
      ],
    },
  ],
};

const SOLUTIONS_2 = {
  features: [
    {
      title: 'Team Management',
      description:
        'Clinic coordinators, team leads, or evaluation team leads can assign and monitor progress of all evaluations from one streamlined dashboard.',
      prompt: 'Closed beta',
      bullets: [
        {
          name: 'Allocate workloads effectively',
          description:
            'Assign and transfer evaluations between professionals to balance workloads across your team. Easily see the number of evaluations each member has in progress.',
          icon: FaBriefcase,
        },
        {
          name: 'Understand the status of all evaluations',
          description:
            'Keep track of important dates, status, and other relevant details for multiple evaluators at the same time.',
          icon: FaTasks,
        },
        {
          name: 'Stay within compliance',
          description:
            'Never miss a due date by tracking evaluations that are near the end of the testing window. ',
          icon: FaCalendarAlt,
        },
      ],
    },
    {
      title: 'Digitized Referral Forms',
      description: 'Securely share intake forms and Evalubox will gather the information you need.',
      prompt: 'Closed beta',
      bullets: [
        {
          name: 'Share intake forms',
          description:
            'Send links to parents, teachers, and nurses and have their responses automatically added to your reports.',
          icon: ShareIcon,
        },
        {
          name: 'Track missing documents',
          description:
            'Send personalized follow-up messages in multiple languages and gather the information you need in a timely manner. ',
          icon: BellIcon,
        },
      ],
    },
    {
      title: 'Peer review',
      description:
        'Maintain consistency across your team, get a second set of eyes on the tough reports, and easily supervise students and new employees.',
      prompt: 'Closed beta',
      bullets: [
        {
          name: 'Find out which evaluations need attention',
          description:
            'Identify the types of evaluations that need procedures written up so that all professionals are testing in the same way.',
          icon: BsFillExclamationTriangleFill,
        },
        {
          name: 'Ensure accuracy and avoid errors',
          description:
            'Feel at ease and confident knowing that every evaluation is accurate and error free before you share it outside of your team.',
          icon: CheckCircleIcon,
        },
      ],
    },
  ],
};

const PRIVACY_COMPLIANCE_SUPPORTED = {
  title: 'Keep important records safe',
  subTitle: `Evalubox is HIPAA compliant and FERPA compliant. HIPAA regulations (The HIPAA Privacy Rule) requires that Covered Entities sign an agreement with their Business Associates. Evalubox will sign a Business Associate Agreement (BAA) for all customers.`,
  link: {
    text: 'Learn more about how we keep your data safe and give you peace of mind.',
    to: '/security/?utm_source=evalubox-home&utm_medium=internal&utm_campaign=february-2024&utm_term=security-cta&utm_content=cta-learn-more',
  },
  logos: [
    {
      src: <HipaaLogo />,
      key: 'hipaa',
    },
    {
      src: <img src={FerpaLogo} alt="Ferpa compliance logo" className="" />,
      key: 'ferpa',
    },
  ],
};

const Home = () => (
  <>
    <SitewideHeader
      borderBottom={false}
      ctaTo="/pricing/?utm_source=evalubox-home&utm_medium=internal&utm_campaign=february-2024&utm_term=top-header&utm_content=cta-get-started"
      ctaText="Get Started"
    />
    {/* <VideoHero /> */}
    <CenteredHero />
    <SolutionIntroSection />
    <div className="py-10 bg-gray-50 relative overflow-hidden" id="product">
      <AlternatingFeatureBlock content={VALUE_PROPS} />
    </div>

    <LandingCTA />
    <div className=" bg-white relative overflow-hidden" id="product">
      <WhoIsItFor />
    </div>

    <ProblemSection />
    <TestimonialsCloud />
    {/* <WhatIsEvalubox /> */}

    {/* <div className="pt-24 bg-gray-50 pb-32" id="solutions">
      <SimpleCenteredHeading
        headline="Streamline your evaluation process"
        lead="A single place to manage your evaluations from initial referral to placement."
      />

      <TextBasedFeatureBlock content={SOLUTIONS} />
    </div>  */}

    {/* <div className="pt-10 pb-4 bg-gray-50 relative overflow-hidden" id="product">
      <AlternatingFeatureBlock content={TEMPLATE} />
    </div> */}

    <div className="py-10 bg-blue-50" id="solutions">
      <SimpleCenteredHeading
        headline="Smart Templates - without copy & paste or find & replace."
        lead="When you or (parent, teacher, or nurse) enters data through a questionnaire, Evalubox will automatically place the data into an editable document for you."
      />

      {/* <TextBasedFeatureBlock content={SOLUTIONS} /> */}
      <AlternatingFeatureBlock content={TEMPLATE} />
    </div>

    {/* <div className="pb-4 bg-gray-50 relative overflow-hidden" id="product">
      <TextBasedFeatureBlock content={SOLUTIONS_2} />
    </div> */}

    <SplitGridLogoCloud content={PRIVACY_COMPLIANCE_SUPPORTED} />

    {/* <div className="pt-14 bg-blue-50 pb-10" id="solutions">
      <SimpleCenteredHeading
        headline="Don’t you think it’s time to make the evaluation process less stressful?"
        lead="Evalubox is a single place to house all of your report paragraphs and templates so you can quickly produce error-free reports. We’ll be there to help you the entire way from when you receive the referral until you hit print!"
      />
    </div> */}

    <HomeFooterCTA />

    <SitewideFooter />
  </>
);

export default Home;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const ProblemSection = () => {
  return (
    <section className="bg-white">
      <div className="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <h2 className="text-center text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          When you're crunched for time, analyzing and writing a comprehensive report can seem
          overwhelming!
        </h2>

        <div className="mt-8 grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-8">
          <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
            <p className="mt-4 text-gray-700 text-xl">
              You've tried Word and Google Docs but you end up spending the little time you have
              manually re-entering information, copy/pasting, and doing find/replace.
            </p>
          </blockquote>

          <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
            <p className="mt-4 text-gray-700 text-xl">
              {/* You're ready to analyze your results and make a diagnostic decision but you end up shuffling through multiple files/docs/papers to find the information you need can be frustrating. */}
              {/* When you sit down to analyze your results to make a diagnostic decision, you end up shuffling through multiple files/docs/papers to find the information you need, which can be time consuming and frustrating. */}
              You're shuffling through multiple files/docs/papers to find the information you need
              to analyze your results so that you can make a diagnostic decision, but putting all
              that information into a cohesive report can be slow and frustrating.
            </p>
          </blockquote>

          <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
            <p className="mt-4 text-gray-700 text-xl">
              You've tried using previous reports (male, female versions) as templates, but they can
              lead to unintended mistakes like incorrect names, pronouns, and information from
              previous reports.
            </p>
          </blockquote>
        </div>
      </div>
    </section>
  );
};

const SolutionIntroSection = () => {
  return (
    <section className="bg-blue-50 mt-10">
      <div className="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <h2 className="text-center text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          {/* Evalubox helps you assemble a comprehensive evaluation report you're proud of  */}
          Stress-Free Report Writing is Just
          <strong className="font-extrabold text-orange-600 sm:block p-2 block">
            Three Steps Away
          </strong>
        </h2>
      </div>
    </section>
  );
};

const SEO_TITLE = 'The easiest way to write an evaluation report';
const LEAD = `Write Comprehensive Speech Language Reports in a Fraction of the Time!`;

export const Head = () => (
  <>
    <SEO title={SEO_TITLE} description={LEAD} />
  </>
);
