import React from 'react';
import { Button, Headline, Lead } from '../..';
import CatalogScreenshot from '../../../../static/images/landing/screenshot-catalog.png';
import HeroForm from './HeroForm';
import { LOGOS } from '../CustomerLogoCloud';
import has from 'lodash/has';
import ImageCarousel from '../../ImageCarousel';
import TemplatePic from '../../../../static/images/logos/TemplatePicEx.png';
import NamePronounExampleVideo from '../../../../static/images/logos/WhatIsEvalubox.mp4';
import RequestAccessForm from './RequestAccessForm';

import ShareParentFormLanguageToggle from '../../../../content/assets/home/forms/ShareMultiLingualForms.png';
import FormsLaptopMockup from '../../../../content/assets/home/forms/FormsLaptopMockup.mp4';
import InstructionsVideo from '../../LanguageProficiency/InstructionsVideo';
const FormsCenteredHero = () => {
  const whiteLogos = [...LOGOS].filter(({ src }) => has(src, 'white')).slice(0, 4);

  return (
    <>
      <div className="relative landing-hero-background overflow-x-hidden pb-10" id="forms-hero">
        <div className="relative pb-0">
          <main className=" mx-auto max-w-7xl px-4 sm:mt-20 sm:px-6 ">
            <div className="container flex flex-col sm:px-6 sm:py-10 mx-auto space-y-6 lg:h-fit lg:py-2 lg:flex-row lg:items-center">
              <div className="w-full lg:w-1/2">
                <div className="lg:max-w-lg">
                  <Headline size="medium" className="text-inherit leading-snug">
                    Gather information faster and get ahead on evaluations.
                  </Headline>

                  <h2 className="mt-5 text-base text-inherit sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
                    {/* Are you tired of chasing down forms from parents, teachers, and nurses? */}
                    {/* SLPs who use online forms gather information 75% faster on average. */}
                    {/* Easily share online referral forms with parents, teachers, nurses and anyalze responses in real-time. */}
                  </h2>
                  <Lead size="medium" className="text-inherit leading-snug pt-4">
                    Effortlessly gather parent, teacher, and health information in multiple
                    languages to create assessment plans and write your reports.
                    <br />
                    <br />
                    <ul className="list-disc list-inside mb-4 pl-2 text-base space-y-2">
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 16 12"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4.5"
                            d="M1 5.917 5.724 10.5 15 1.5"
                          />
                        </svg>
                        <p className="text-lg md:text-xl text-gray-900">Customizable Questions</p>
                      </li>
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 16 12"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4.5"
                            d="M1 5.917 5.724 10.5 15 1.5"
                          />
                        </svg>
                        <p className="text-lg md:text-xl text-gray-900">Multilingual Magic</p>
                      </li>
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 16 12"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4.5"
                            d="M1 5.917 5.724 10.5 15 1.5"
                          />
                        </svg>
                        <p className="text-lg md:text-xl text-gray-900">Secure Single-Use Links</p>
                      </li>
                    </ul>
                    {/* These secure questionnaires are delivered via text or email with all responses being returned electronically and organized in one place. 
                    Questionnaires can be sent in 16 different languages and responses are translated automatically.

 
                    <ul className="list-disc list-inside mb-4 pl-8 text-base space-y-2">
                <li><strong>Customizable Questions:</strong> Choose exactly what you need to ask, ensuring every form is tailored to your specific needs.</li>
                <li><strong>Multilingual Magic:</strong> Send forms in up to 16 different languages effortlessly. We'll handle the translations for you, so language barriers are a thing of the past.</li>
                <li><strong>Automatic Notifications:</strong> Receive real-time updates as soon as forms are completed, and get all responses translated back to English automatically.</li>
                <li><strong>Single-Use Links:</strong> All forms are single-use links that expire once completed, ensuring data safety and security. They are HIPAA and FERPA compliant.</li>
                <li><strong>Personalized Data:</strong> Each form is unique to each client, making it easy to manage and track personalized data for all your clients.</li>
                <li><strong>Progress Tracking:</strong> You can track the progress of each form sent, making it easy to manage and follow up as needed.</li>
            </ul>  */}
                    {/* With Evalubox forms, you can effortlessly gather information for creating
                    assessment plans, meet the language needs of your clients, and never lose a form
                    again. With our electronic medical forms, you can send questionnaires in 16
                    different languages, automatically translate responses, and generate assessment
                    plans. */}
                  </Lead>
                  {/* <ul className="list-disc pt-4">
                    <li className="flex items-center space-x-3">
                      <svg
                        className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 16 12"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M1 5.917 5.724 10.5 15 1.5"
                        />
                      </svg>
                      <p className="text-lg md:text-xl font-bold text-black">
                        Gather information faster
                      </p>
                    </li>
                    <li className="flex items-center space-x-3">
                      <svg
                        className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 16 12"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M1 5.917 5.724 10.5 15 1.5"
                        />
                      </svg>
                      <p className="text-lg md:text-xl font-bold text-black">
                        Share in multiple languages
                      </p>
                    </li>
                    <li className="flex items-center space-x-3">
                      <svg
                        className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 16 12"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M1 5.917 5.724 10.5 15 1.5"
                        />
                      </svg>
                      <p className="text-lg md:text-xl font-bold text-black">
                        Know status of every form
                      </p>
                    </li>
                  </ul> */}
                  <div className="my-5 md:mt-8">
                    {/* <div className="flex w-full md:justify-start justify-center items-end">
                    <RequestAccessForm from={"home-page"} showTC={true} />
                    </div> */}
                    <div className="mt-8 flex justify-left">
                      {/* <div className="inline-flex">
                        <Button
                          link={true}
                          to="#solutions"
                          color="primary"
                          text="Try it for free"
                        />
                      </div> */}
                      <div className="inline-flex">
                        <Button
                          link={true}
                          to="https://app.evalubox.com/register?intent=forms&utm_medium=display&utm_source=forms&utm_campaign=forms_to_evalubox_register&utm_content=cta-hero-try-it-for-free"
                          color="primary"
                          text="Try it For Free"
                          size="large"
                        />
                      </div>

                      {/* <div className="ml-3 inline-flex">
                        <Button link={true} to="/request-demo/" color="inset" text="Request Demo" />
                      </div> */}
                    </div>
                    {/* <p className="mt-2 w-full text-sm text-left text-gray-500">
                      No upfront costs required to get started.
                    </p> */}
                  </div>
                </div>
              </div>
              {/* <div className="relative w-full">
                  <div className="relative">
                    <img
                      className="object-cover object-center mx-auto rounded-lg shadow-2xl drop-shadow-2xl"
                      // alt={templateImg.title}
                      src={ShareParentFormLanguageToggle}
                    />
                  </div>
              </div> */}

              <div className="flex items-center justify-center w-full lg:h-96 lg:w-1/2">
                {/* <img
                className="object-scale-down w-full h-full object-center mx-auto rounded-lg shadow-2xl drop-shadow-2xl"
                // alt={templateImg.title}
                src={ShareParentFormLanguageToggle}
              /> */}
                {/* <InstructionsVideo
                videoSrcURL={
                  '"https://cdn.app.evalubox.com/marketing-site/forms-product/FormsWorkflow.mp4'
                }
                thumbnailSrc={
                  'https://cdn.app.evalubox.com/marketing-site/language-exposure/instructional_video_thumbnail.png'
                }
                videoTitle={
                  'Instructional video on how to quickly gather information about the language use of our bilingual kids with language exposure and use calculator.'
                }
              /> */}

                <video
                  preload="auto"
                  className="mx-auto rounded-md lg:max-w-2xl border"
                  autoPlay
                  loop
                  controls
                >
                  <source
                    src={
                      'https://cdn.app.evalubox.com/marketing-site/forms-product/FormsWorkflow.mp4'
                    }
                    type="video/mp4"
                  />
                  your browser is not supported!
                </video>
                {/* <iframe
                  className="rounded-md w-full h-full"
                  src="https://app.searchie.io/file/P5qn4n30Dr/embed?autoplay=0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe> */}
              </div>
            </div>
          </main>
          {/* <div className="max-w-7xl mt-20 mx-auto">
            <ImageCarousel />
          </div> */}
        </div>
      </div>

      <script async src="https://player.vimeo.com/api/player.js" />
    </>
  );
};

export default FormsCenteredHero;
