import React from 'react';
import { Button, HelpText } from '../../components';

import TeamsTierPriceDisplay from './TeamsTierPriceDisplay';
import TeamsTierBenefits from './TeamsTierBenefits';
import TierContainer from './TierContainer';
import TierName from './TierName';
import TierDescription from './TierDescription';
import TIERS from './tiers';
const TeamsTier = ({ ctaComponent, selectedPlan, setSelectedPlan }: any) => (
  <button onClick={() => setSelectedPlan('TEAMS')}>
    <TierContainer highlight={selectedPlan === 'TEAMS' ? true : false}>
      <div className="p-6">
        <TierName name={TIERS.teams.name} />
        <TierDescription description={TIERS.teams.description} />
        <TeamsTierPriceDisplay />

        <div className="">
          {ctaComponent}
          <br />
        </div>
      </div>

      {/* <TeamsTierBenefits /> */}
    </TierContainer>
  </button>
);

export default TeamsTier;
