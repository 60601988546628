import React from 'react';
import { FaLanguage, FaCalculator } from 'react-icons/fa';
import { IconContext } from 'react-icons';

const LanguageExposureIcon = ({ showIconText, ...rest }) => (
  <IconContext.Provider value={{ size: '70' }}>
    <div
      {...rest}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '140px',
        height: '140px',
      }}
    >
      <FaLanguage />
      {/* <FaCalculator style={{ position: 'absolute', top: '60px', left: '35px' }} /> */}
      {showIconText && (
        <div
          style={{ marginTop: '10px', fontSize: '12px', fontWeight: 'bold', textAlign: 'center' }}
        >
          Language Exposure & Use Calculator
        </div>
      )}
    </div>
  </IconContext.Provider>
);

export default LanguageExposureIcon;

export const LanguageExposureIconSmall = ({ showIconText, ...rest }) => (
  <IconContext.Provider value={{ size: '70' }}>
    <div
      {...rest}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        // width: '140px',
        // height: '140px',
      }}
    >
      <FaLanguage />
      {/* <FaCalculator style={{ position: 'absolute', top: '60px', left: '35px' }} /> */}
      {showIconText && (
        <div
          style={{ marginTop: '10px', fontSize: '12px', fontWeight: 'bold', textAlign: 'center' }}
        >
          Language Exposure & Use Calculator
        </div>
      )}
    </div>
  </IconContext.Provider>
);
