export const USD_TO_EUR_EXCHANGE_RATE = 1;

export const PER_EVALUATION_PRICES = [
  Object.freeze({
    id: 30,
    name: '30 evaluations',
    usdCentCostPerDevPerMonth: 100,
  }),

  Object.freeze({
    id: 60,
    name: '60 evaluations',
    usdCentCostPerDevPerMonth: 100,
  }),

  Object.freeze({
    id: 70,
    name: '70 evaluations',
    usdCentCostPerDevPerMonth: 90,
  }),

  Object.freeze({
    id: 80,
    name: '80 evaluations',
    usdCentCostPerDevPerMonth: 90,
  }),

  Object.freeze({
    id: 90,
    name: '90 evaluations',
    usdCentCostPerDevPerMonth: 90,
  }),

  Object.freeze({
    id: 100,
    name: '100 evaluations',
    usdCentCostPerDevPerMonth: 80,
  }),

  Object.freeze({
    id: 110,
    name: '110 evaluations',
    usdCentCostPerDevPerMonth: 80,
  }),

  Object.freeze({
    id: 120,
    name: '120 evaluations',
    usdCentCostPerDevPerMonth: 80,
  }),

  Object.freeze({
    id: 130,
    name: '130 evaluations',
    usdCentCostPerDevPerMonth: 80,
  }),

  Object.freeze({
    id: 140,
    name: '140 evaluations',
    usdCentCostPerDevPerMonth: 80,
  }),

  Object.freeze({
    id: 150,
    name: '150 evaluations',
    usdCentCostPerDevPerMonth: 70,
  }),
];

export const PER_EXAMINER_POWER_PRICES = [
  Object.freeze({
    id: 1,
    name: '1 examiner',
    usdCentCostPerExaminerAnually: 2977,
    usdCentCostPerExaminerPerMonth: 3870,
    usdCentCostPerExaminerAnuallyNew: 1500,
    usdCentCostPerExaminerPerMonthNew: 2000,
  }),
];

export const PER_EXAMINER_PRO_PRICES = [
  Object.freeze({
    id: 1,
    name: '1 examiner',
    usdCentCostPerExaminerAnually: 1250,
    usdCentCostPerExaminerPerMonth: 1500,
  }),
];

export const PER_EXAMINER_GROWTH_PRICES = [
  Object.freeze({
    id: 1,
    name: '1 examiner',
    usdCentCostPerExaminerAnually: 2977,
    usdCentCostPerExaminerPerMonth: 3870,
  }),
];

export const PAY_AS_YOU_GO_PRICES = [
  Object.freeze({
    id: 1,
    name: 'Pay As You Go',
    usdCentCostPerUse: 500,
  }),
];
