import React, { useState } from 'react';
import { Page } from '../../../components/layouts';
import { Button, Headline, Lead, SEO, TextLink } from '../../../components';
import { FooterCTA, SimpleCenteredHeading } from '../../../components/landing';
import { ToolsFooterCTA } from '../../../components/landing/FooterCTA';

import ChronologicalAgeCalculatorImage from '../../../../content/assets/tools/age-calculator/age-chronological-calculator.jpeg';
import ChronologicalAgeCalculatorBanner from '../../../../content/assets/tools/age-calculator/chronological-age-calculator-banner.jpeg';
import ChronologicalAgeCalculatorPearson from '../../../../content/assets/tools/age-calculator/chronological-age-calculator-pearson.png';
import EmailCaptureForm from '../../../components/CallToAction/EmailCaptureForm';
import EmailFormCallToAction from '../../../components/CallToAction/EmailCallToAction';
import { RequestLanguageExposureToInboxCTA } from '../../../components/CallToAction/RequestTemplateToInbox';
import LanguageExposureReportEmailFormCallToAction from '../../../components/CallToAction/LangageExposureReportEmailCallToAction';
import LanguageCalculator from '../../../components/LanguageProficiency/LanguageCalculator';
import ResourcesPage from '../../../components/layouts/ResourcesPage';

const LanguageUseExposureCalculator = () => {
  const [dob, setDob] = useState('');
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [results, setResults] = useState({
    fullAge: '',
    ageInYears: '',
    ageInMonths: '',
  });

  const calculateAge = () => {
    const birthDate = new Date(dob);
    const todayDate = new Date(endDate);

    const diffInMonths =
      (todayDate.getFullYear() - birthDate.getFullYear()) * 12 +
      todayDate.getMonth() -
      birthDate.getMonth();

    const fullAge = `${Math.floor(diffInMonths / 12)};${diffInMonths % 12}`;

    setResults({
      fullAge,
      ageInYears: Math.floor(diffInMonths / 12).toString(),
      ageInMonths: (diffInMonths % 12).toString(),
    });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <>
      <ResourcesPage titleDivide={true}>
        <div className="mx-auto max-w-screen-xl ">
          <LanguageCalculator />
          <InformationSection />
          <LanguageExposureFooterCTA />
        </div>
      </ResourcesPage>
    </>
  );
};

export default LanguageUseExposureCalculator;

export const Head = () => (
  <>
    <SEO
      title={`Language Use and Exposure Calculator`}
      description="Easily calculate the percentage of time clients speaks and hears English compared to their native language."
    />
  </>
);

export const LanguageExposureFooterCTA = () => (
  <div className="bg-white">
    <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-32 lg:px-8">
      {/* <div className="pb-3">
        <Headline el="h2">
          <span className="block">Start Using Evalubox Today.</span>
        </Headline>
        <Lead>
        When you're crunched for time, analyzing and writing a comprehensive report can seem overwhelming! 
        </Lead>
      </div> */}

      <SimpleCenteredHeading
        headline="Start Using Evalubox Today."
        lead="Write Comprehensive Speech Language Reports in a Fraction of the Time!"
      />

      <div className="lg:mt-4 mx-auto max-w-2xl">
        {/* <RequestAccessForm from={"home-page"} showTC={true} /> */}
        <div className="inline-flex">
          <Button
            link={true}
            to="/?utm_medium=display&utm_source=language-exposure-calculator&utm_campaign=language_exposure_calculator_to_evalubox_home&utm_content=footer-cta-learn-more"
            color="primary"
            text="Learn More"
            size="large"
          />
        </div>

        <div className="ml-3 inline-flex">
          <Button
            link={true}
            to="/request-demo/?utm_medium=display&utm_source=language-exposure-calculator&utm_campaign=language_exposure_calculator_to_evalubox_demo&utm_content=footer-cta-request-demo"
            color="inset"
            text="Request Demo"
            size="large"
          />
        </div>
      </div>
    </div>
  </div>
);

export const InformationSection = () => {
  return (
    <div className="p-6 mt-20 bg-white shadow-md rounded-lg">
      <Headline size="small" el="h2" className="mb-4 text-center">
        Why Gather Information about Language Exposure and Use?
      </Headline>
      {/* <Lead className="mb-4">{lead}</Lead> */}
      {/* <h1 className="text-2xl font-bold mb-4">Why Gather Information about Language Exposure and Use?</h1> */}
      <p className="text-xl mb-4">
        Why is it important to gather information about language exposure and use? When we evaluate
        and work with bilingual students, knowing what their current exposure to each language is
        helps us to understand what types of language patterns we might hear them use. What’s more,
        current research by Peña, Bedore, and colleagues (Bedore et al., 2018; Peña et al., 2020)
        indicates that, when children have current exposure and use that is less than 30% in one
        language, it was found that the results of standardized testing in that language did not
        inform the diagnostic decision. In their case, the research focused on the{' '}
        <TextLink
          color="primary"
          target="_blank"
          to="https://products.brookespublishing.com/Bilingual-English-Spanish-Assessment-BESA-P1044.aspx"
        >
          Bilingual English Spanish Assessment (BESA)
        </TextLink>
        , which has a Spanish portion and an English portion. Knowing this information helps us to
        plan our evaluation and potentially save a lot of time.
      </p>
      <p className="text-gray-500 text-sm py-2">
        Bedore, L. M., Peña, E. D., Anaya, J. B., Nieto, R., Lugo-Neris, M. J., & Baron, A. (2018).
        Understanding disorder within variation: Production of English grammatical forms by English
        language learners. Language, Speech, and Hearing Services in Schools, 49(2), 277-291.
      </p>
      <p className="text-gray-500 text-sm py-2">
        Peña, E. D., Bedore, L. M., Shivabasappa, P., & Niu, L. (2020). Effects of divided input on
        bilingual children with language impairment. International journal of bilingualism, 24(1),
        62-78.
      </p>
    </div>
  );
};

const LanguageCalculator2 = () => {
  const [selectedOption, setSelectedOption] = useState('teacherParentInput'); // Default option
  const [teacherInput, setTeacherInput] = useState({
    speaking: {
      primaryTeacher: 0,
      student: 0,
      otherPersonnel: 0,
    },
    listening: {
      primaryTeacher: 0,
      student: 0,
      otherPersonnel: 0,
    },
  });
  const [parentInput, setParentInput] = useState({
    speaking: {
      afterSchool: 0,
      eveningsNights: 0,
      weekends: 0,
    },
    listening: {
      afterSchool: 0,
      eveningsNights: 0,
      weekends: 0,
    },
  });

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleTeacherInputChange = (event, type, category) => {
    setTeacherInput({
      ...teacherInput,
      [type]: {
        ...teacherInput[type],
        [category]: parseInt(event.target.value),
      },
    });
  };

  const handleParentInputChange = (event, type, category) => {
    setParentInput({
      ...parentInput,
      [type]: {
        ...parentInput[type],
        [category]: parseInt(event.target.value),
      },
    });
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Language Exposure and Use Calculator</h1>
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Select Option:</h2>
        <select
          value={selectedOption}
          onChange={handleOptionChange}
          className="border border-gray-300 rounded p-2 w-full mb-4"
        >
          <option value="teacherParentInput">Teacher and Parent Input</option>
          <option value="teacherInput">Teacher Input Only</option>
          <option value="parentInputAttendsSchool">Parent Input Only - Attends School</option>
          <option value="parentInputDoesNotAttendSchool">
            Parent Input Only - Does Not Attend School
          </option>
        </select>
        {selectedOption === 'teacherParentInput' && (
          <div>
            {/* Teacher input section */}
            <TeacherInput />
            {/* Parent input section */}
            <hr className="mt-10 py-4" />
            <ParentInput />
          </div>
        )}
        {selectedOption === 'teacherInput' && (
          <div>
            {/* Teacher input section */}
            <TeacherInput />
          </div>
        )}
        {selectedOption === 'parentInputAttendsSchool' && (
          <div>
            {/* Teacher input section */}
            <ParentInput />
          </div>
        )}
        {selectedOption === 'parentInputDoesNotAttendSchool' && (
          <div>
            {/* Teacher input section */}
            <ParentNoSchoolInput />
          </div>
        )}
        {/* {selectedOption !== 'teacherInput' && (
          <div>
            <h2 className="text-xl font-semibold mb-2">Parent Input</h2>
            <div>
              <h3 className="text-lg font-semibold mb-2">Speaking outside of School</h3>
              <div>
                <label className="block mb-2">After School</label>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={parentInput.speaking.afterSchool}
                  onChange={(e) => handleParentInputChange(e, 'speaking', 'afterSchool')}
                  className="w-full"
                />
                <p className="text-center">{parentInput.speaking.afterSchool}%</p>
              </div>
              <div>
                <label className="block mb-2">Evenings/Nights</label>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={parentInput.speaking.eveningsNights}
                  onChange={(e) => handleParentInputChange(e, 'speaking', 'eveningsNights')}
                  className="w-full"
                />
                <p className="text-center">{parentInput.speaking.eveningsNights}%</p>
              </div>
              <div>
                <label className="block mb-2">Weekends</label>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={parentInput.speaking.weekends}
                  onChange={(e) => handleParentInputChange(e, 'speaking', 'weekends')}
                  className="w-full"
                />
                <p className="text-center">{parentInput.speaking.weekends}%</p>
              </div>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2">Listening outside of School</h3>
              <div>
                <label className="block mb-2">After School</label>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={parentInput.listening.afterSchool}
                  onChange={(e) => handleParentInputChange(e, 'listening', 'afterSchool')}
                  className="w-full"
                />
                <p className="text-center">{parentInput.listening.afterSchool}%</p>
              </div>
              <div>
                <label className="block mb-2">Evenings/Nights</label>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={parentInput.listening.eveningsNights}
                  onChange={(e) => handleParentInputChange(e, 'listening', 'eveningsNights')}
                  className="w-full"
                />
                <p className="text-center">{parentInput.listening.eveningsNights}%</p>
              </div>
              <div>
                <label className="block mb-2">Weekends</label>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={parentInput.listening.weekends}
                  onChange={(e) => handleParentInputChange(e, 'listening', 'weekends')}
                  className="w-full"
                />
                <p className="text-center">{parentInput.listening.weekends}%</p>
              </div>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

const ParentNoSchoolInput = () => {
  const [parentInput, setParentInput] = useState({
    speaking: {
      weekdayMornings: 50,
      weekdayDuringDay: 50,
      weekdayAfternoonsEvenings: 50,
      weekdayNights: 50,
      weekendDays: 50,
    },
    listening: {
      weekdayMornings: 50,
      weekdayDuringDay: 50,
      weekdayAfternoonsEvenings: 50,
      weekdayNights: 50,
      weekendDays: 50,
    },
  });

  const areas = [
    { key: 'weekdayMornings', label: 'Weekday Mornings' },
    { key: 'weekdayDuringDay', label: 'Weekday During the Day' },
    { key: 'weekdayAfternoonsEvenings', label: 'Weekday Afternoons/Evenings' },
    { key: 'weekdayNights', label: 'Weekday Nights' },
    { key: 'weekendDays', label: 'Weekend Days' },
  ];
  const handleParentInputChange = (event, type, category) => {
    setParentInput({
      ...parentInput,
      [type]: {
        ...parentInput[type],
        [category]: parseInt(event.target.value),
      },
    });
  };

  const renderParentLabel = (value, type) => {
    const speaks =
      parentInput[type][value] === 0
        ? 'Speaks only in Home Language'
        : parentInput[type][value] === 100
        ? 'Speaks only in English'
        : 'Speaks English';
    const listens =
      parentInput[type][value] === 0
        ? 'Listens only in Home Language'
        : parentInput[type][value] === 100
        ? 'Listens only in English'
        : 'Listens to English';

    return type === 'speaking' ? speaks : listens;
  };

  const calculateTotalHours = (type) => {
    const hoursWeekdayMornings = (10 * parentInput[type].weekdayMornings) / 100;
    const hoursWeekdayDuringDay = (40 * parentInput[type].weekdayDuringDay) / 100;
    const hoursWeekdayAfternoonsEvenings = (15 * parentInput[type].weekdayAfternoonsEvenings) / 100;
    const hoursWeekdayNights = (15 * parentInput[type].weekdayNights) / 100;
    const hoursWeekendDays = (32 * parentInput[type].weekendDays) / 100;

    const totalHoursInEnglish =
      hoursWeekdayMornings +
      hoursWeekdayDuringDay +
      hoursWeekdayAfternoonsEvenings +
      hoursWeekdayNights +
      hoursWeekendDays;
    const totalPercentageInEnglish = (totalHoursInEnglish / 112) * 100; // Total hours at home is 112

    const totalHoursByArea = {
      weekdayMornings: hoursWeekdayMornings.toFixed(2),
      weekdayDuringDay: hoursWeekdayDuringDay.toFixed(2),
      weekdayAfternoonsEvenings: hoursWeekdayAfternoonsEvenings.toFixed(2),
      weekdayNights: hoursWeekdayNights.toFixed(2),
      weekendDays: hoursWeekendDays.toFixed(2),
      overall: totalHoursInEnglish.toFixed(2),
      percentageInEnglish: totalPercentageInEnglish.toFixed(0),
      percentageInHomeLanguage: (100 - totalPercentageInEnglish).toFixed(0),
    };

    return totalHoursByArea;
  };

  const totalSpeakingHours = calculateTotalHours('speaking');
  const totalListeningHours = calculateTotalHours('listening');

  // Calculating averages
  const averageInputOutputEnglish = (
    (parseFloat(totalSpeakingHours.percentageInEnglish) +
      parseFloat(totalListeningHours.percentageInEnglish)) /
    2
  ).toFixed(0);
  const averageInputOutputHomeLanguage = (100 - parseFloat(averageInputOutputEnglish)).toFixed(0);

  return (
    <div>
      <h2 className="text-xl font-semibold mb-2">Parent Input - Does Not Attend School</h2>
      <div className="md:grid md:grid-cols-2 md:gap-x-10 ">
        <div>
          <h3 className="text-lg font-semibold mb-2">Speaking</h3>
          {areas.map((area, index) => (
            <div key={index}>
              <label className="block mb-2">{area.label}</label>
              <input
                type="range"
                min="0"
                max="100"
                value={parentInput.speaking[area.key]}
                onChange={(e) => handleParentInputChange(e, 'speaking', area.key)}
                className="w-full"
              />
              <p className="text-center">
                {parentInput.speaking[area.key]}% - {renderParentLabel(area.key, 'speaking')}
              </p>
            </div>
          ))}
          {/* Add similar inputs for weekdayDuringDay, weekdayAfternoonsEvenings, weekdayNights, and weekendDays */}
          <table className="w-full mb-4 border border-collapse border-gray-800 hidden">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-800 px-4 py-2">Area</th>
                <th className="border border-gray-800 px-4 py-2">Total Hours in English</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Weekday Mornings</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.weekdayMornings} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Weekday During Day</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.weekdayDuringDay} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Weekday Afternoons/Evenings</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.weekdayAfternoonsEvenings} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Weekday Nights</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.weekdayNights} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Weekend Days</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.weekendDays} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Total Overall Hours in English</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.overall} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Percentage of Time Speaking English at Home
                </td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.percentageInEnglish}%
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Percentage of Time Speaking in Home Language at Home
                </td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.percentageInHomeLanguage}%
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2" colSpan="2">
                  Assumptions: 168 hours in a week, 8 hours per day sleeping, 40 hours at school,
                  and 72 hours outside of school. We assume students spend 3 hours per day with
                  students and with teachers for 5 days a week, bringing a total of 15 hours per
                  week. We assume students spend 2 hours a day with other school personnel for 5
                  days a week, bringing the total to 10 hours per week with other school personnel.
                  In total, the weekly hours combined to be 40 hours.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Listening</h3>
          {areas.map((area, index) => (
            <div key={index}>
              <label className="block mb-2">{area.label}</label>
              <input
                type="range"
                min="0"
                max="100"
                value={parentInput.listening[area.key]}
                onChange={(e) => handleParentInputChange(e, 'listening', area.key)}
                className="w-full"
              />
              <p className="text-center">
                {parentInput.listening[area.key]}% - {renderParentLabel(area.key, 'listening')}
              </p>
            </div>
          ))}

          <table className="w-full mb-4 border border-collapse border-gray-800 hidden">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-800 px-4 py-2">Area</th>
                <th className="border border-gray-800 px-4 py-2">Total Hours in English</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Weekday Mornings</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.weekdayMornings} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Weekday During Day</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.weekdayDuringDay} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Weekday Afternoons/Evenings</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.weekdayAfternoonsEvenings} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Weekday Nights</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.weekdayNights} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Weekend Days</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.weekendDays} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Total Overall Hours in English</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.overall} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Percentage of Time Listening English at Home
                </td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.percentageInEnglish}%
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Percentage of Time Listening in Home Language at Home
                </td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.percentageInHomeLanguage}%
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2" colSpan="2">
                  Assumptions: 168 hours in a week, 8 hours per day sleeping, 40 hours at school,
                  and 72 hours outside of school. We assume students spend 3 hours per day with
                  students and with teachers for 5 days a week, bringing a total of 15 hours per
                  week. We assume students spend 2 hours a day with other school personnel for 5
                  days a week, bringing the total to 10 hours per week with other school personnel.
                  In total, the weekly hours combined to be 40 hours.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Summary table */}
      <div className="mt-10">
        <h2 className="text-xl font-semibold mb-2">Summary</h2>
        <table className="w-full mb-4 border border-collapse border-gray-800">
          <caption className="caption-top ">
            The parent survey indicated that the child is not yet in school, and that they they hear
            and use English {averageInputOutputEnglish}% of the time and hear and use (home lanuage){' '}
            {averageInputOutputHomeLanguage}% of the time.
          </caption>

          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-800 px-4 py-2">Category</th>
              <th className="border border-gray-800 px-4 py-2">Average Percentage</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-800 px-4 py-2">Overall Input/Output in English</td>
              <td className="border border-gray-800 px-4 py-2">{averageInputOutputEnglish}%</td>
            </tr>
            <tr>
              <td className="border border-gray-800 px-4 py-2">
                Overall Input/Output in Home Language
              </td>
              <td className="border border-gray-800 px-4 py-2">
                {averageInputOutputHomeLanguage}%
              </td>
            </tr>
            <tr>
              <td className="border border-gray-800 px-4 py-2" colSpan="2">
                The parent survey indicated that the child is not yet in school, and that they they
                hear and use English {averageInputOutputEnglish}% of the time and hear and use (home
                lanuage) {averageInputOutputHomeLanguage}% of the time.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
const ParentInput = () => {
  const [parentInput, setParentInput] = useState({
    speaking: {
      afterSchool: 50,
      eveningsNights: 50,
      weekends: 50,
    },
    listening: {
      afterSchool: 50,
      eveningsNights: 50,
      weekends: 50,
    },
  });
  const handleParentInputChange = (event, type, category) => {
    setParentInput({
      ...parentInput,
      [type]: {
        ...parentInput[type],
        [category]: parseInt(event.target.value),
      },
    });
  };

  const renderParentLabel = (value, type) => {
    const speaks =
      parentInput[type][value] === 0
        ? 'Speaks only in Home Language outside of School'
        : parentInput[type][value] === 100
        ? 'Speaks only in English outside of School'
        : 'Speaks English outside of School';
    const listens =
      parentInput[type][value] === 0
        ? 'Listens only in Home Language outside of School'
        : parentInput[type][value] === 100
        ? 'Listens only in English outside of School'
        : 'Listens to English outside of School';

    return type === 'speaking' ? speaks : listens;
  };

  const calculateTotalHours = (type) => {
    const hoursAfterSchool = (15 * parentInput[type].afterSchool) / 100;
    const hoursEveningsNights = (15 * parentInput[type].eveningsNights) / 100;
    const hoursWeekends = (32 * parentInput[type].weekends) / 100;

    const totalHoursInEnglish = hoursAfterSchool + hoursEveningsNights + hoursWeekends;
    const totalPercentageInEnglish = (totalHoursInEnglish / 72) * 100; // Total school hours is 40

    const totalHoursByArea = {
      afterSchool: hoursAfterSchool.toFixed(2),
      eveningsNights: hoursEveningsNights.toFixed(2),
      weekends: hoursWeekends.toFixed(2),
      overall: totalHoursInEnglish.toFixed(2),
      percentageInEnglish: totalPercentageInEnglish.toFixed(0),
      percentageInHomeLanguage: (100 - totalPercentageInEnglish).toFixed(0),
    };

    return totalHoursByArea;
  };

  const totalSpeakingHours = calculateTotalHours('speaking');
  const totalListeningHours = calculateTotalHours('listening');

  // Calculating averages
  const averageInputOutputEnglish = (
    (parseFloat(totalSpeakingHours.percentageInEnglish) +
      parseFloat(totalListeningHours.percentageInEnglish)) /
    2
  ).toFixed(0);
  const averageInputOutputHomeLanguage = (100 - parseFloat(averageInputOutputEnglish)).toFixed(0);
  const [showCalculations, setShowCalculations] = useState(false);

  return (
    <div>
      <h2 className="text-xl font-semibold mb-2">Parent Input</h2>
      <div className="md:grid md:grid-cols-2 md:gap-x-10 ">
        <div>
          <h3 className="text-lg font-semibold mb-2">Speaking outside of School</h3>
          <div>
            <label className="block mb-2">After School</label>
            <input
              type="range"
              min="0"
              max="100"
              value={parentInput.speaking.afterSchool}
              onChange={(e) => handleParentInputChange(e, 'speaking', 'afterSchool')}
              className="w-full"
            />
            <p className="text-center">
              {parentInput.speaking.afterSchool}% - {renderParentLabel('afterSchool', 'speaking')}
            </p>
          </div>
          <div>
            <label className="block mb-2">Evenings/Nights</label>
            <input
              type="range"
              min="0"
              max="100"
              value={parentInput.speaking.eveningsNights}
              onChange={(e) => handleParentInputChange(e, 'speaking', 'eveningsNights')}
              className="w-full"
            />
            <p className="text-center">
              {parentInput.speaking.eveningsNights}% -{' '}
              {renderParentLabel('eveningsNights', 'speaking')}
            </p>
          </div>
          <div>
            <label className="block mb-2">Weekends</label>
            <input
              type="range"
              min="0"
              max="100"
              value={parentInput.speaking.weekends}
              onChange={(e) => handleParentInputChange(e, 'speaking', 'weekends')}
              className="w-full"
            />
            <p className="text-center">
              {parentInput.speaking.weekends}% - {renderParentLabel('weekends', 'speaking')}
            </p>
          </div>
          <button onClick={() => setShowCalculations((prev) => !prev)}>
            {!showCalculations ? 'Show Calculations' : 'Hide Calculations'}
          </button>
          {showCalculations && (
            <table className="w-full mb-4 border border-collapse border-gray-800">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border border-gray-800 px-4 py-2">Area</th>
                  <th className="border border-gray-800 px-4 py-2">Total Hours in English</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-gray-800 px-4 py-2">After School</td>
                  <td className="border border-gray-800 px-4 py-2">
                    {totalSpeakingHours.afterSchool} hours
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-800 px-4 py-2">Evenings/Nights</td>
                  <td className="border border-gray-800 px-4 py-2">
                    {totalSpeakingHours.eveningsNights} hours
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-800 px-4 py-2">Eeekends</td>
                  <td className="border border-gray-800 px-4 py-2">
                    {totalSpeakingHours.weekends} hours
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-800 px-4 py-2">
                    Total Overall Hours in English
                  </td>
                  <td className="border border-gray-800 px-4 py-2">
                    {totalSpeakingHours.overall} hours
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-800 px-4 py-2">
                    Percentage of Time Speaking in English Outside of School
                  </td>
                  <td className="border border-gray-800 px-4 py-2">
                    {totalSpeakingHours.percentageInEnglish}%
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-800 px-4 py-2">
                    Percentage of Time Speaking in Home Language Outside School
                  </td>
                  <td className="border border-gray-800 px-4 py-2">
                    {totalSpeakingHours.percentageInHomeLanguage}%
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-800 px-4 py-2" colSpan="2">
                    Assumptions: 168 hours in a week, 8 hours per day sleeping, 40 hours at school,
                    and 72 hours outside of school. We assume students spend 3 hours per day with
                    students and with teachers for 5 days a week, bringing a total of 15 hours per
                    week. We assume students spend 2 hours a day with other school personnel for 5
                    days a week, bringing the total to 10 hours per week with other school
                    personnel. In total, the weekly hours combined to be 40 hours.
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Listening outside of School</h3>
          <div>
            <label className="block mb-2">After School</label>
            <input
              type="range"
              min="0"
              max="100"
              value={parentInput.listening.afterSchool}
              onChange={(e) => handleParentInputChange(e, 'listening', 'afterSchool')}
              className="w-full"
            />
            <p className="text-center">
              {parentInput.listening.afterSchool}% - {renderParentLabel('weekends', 'listening')}
            </p>
          </div>
          <div>
            <label className="block mb-2">Evenings/Nights</label>
            <input
              type="range"
              min="0"
              max="100"
              value={parentInput.listening.eveningsNights}
              onChange={(e) => handleParentInputChange(e, 'listening', 'eveningsNights')}
              className="w-full"
            />
            <p className="text-center">
              {parentInput.listening.eveningsNights}% - {renderParentLabel('weekends', 'listening')}
            </p>
          </div>
          <div>
            <label className="block mb-2">Weekends</label>
            <input
              type="range"
              min="0"
              max="100"
              value={parentInput.listening.weekends}
              onChange={(e) => handleParentInputChange(e, 'listening', 'weekends')}
              className="w-full"
            />
            <p className="text-center">
              {parentInput.listening.weekends}% - {renderParentLabel('weekends', 'listening')}
            </p>
          </div>

          <table className="w-full mb-4 border border-collapse border-gray-800 hidden">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-800 px-4 py-2">Area</th>
                <th className="border border-gray-800 px-4 py-2">Total Hours in English</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-800 px-4 py-2">After School</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.afterSchool} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Evenings/Nights</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.eveningsNights} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Eeekends</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.weekends} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Total Overall Hours in English</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.overall} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Percentage of Time Listening in English Outside of School
                </td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.percentageInEnglish}%
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Percentage of Time Listening in Home Language Outside School
                </td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.percentageInHomeLanguage}%
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2" colSpan="2">
                  Assumptions: 168 hours in a week, 8 hours per day sleeping, 40 hours at school,
                  and 72 hours outside of school. We assume students spend 3 hours per day with
                  students and with teachers for 5 days a week, bringing a total of 15 hours per
                  week. We assume students spend 2 hours a day with other school personnel for 5
                  days a week, bringing the total to 10 hours per week with other school personnel.
                  In total, the weekly hours combined to be 40 hours.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Summary table */}
      <div className="mt-10">
        <h2 className="text-xl font-semibold mb-2">Summary</h2>
        <table className="w-full mb-4 border border-collapse border-gray-800">
          <caption className="caption-top ">
            The parent survey indicated that, during the child's time at outside of school, which
            represents approximately two-thirds of their waking hours, they hear and use English{' '}
            {averageInputOutputEnglish}% of the time and hear and use (home language){' '}
            {averageInputOutputHomeLanguage}% of the time.
          </caption>

          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-800 px-4 py-2">Category</th>
              <th className="border border-gray-800 px-4 py-2">Average Percentage</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-800 px-4 py-2">
                Overall Input/Output in English Outside of School
              </td>
              <td className="border border-gray-800 px-4 py-2">{averageInputOutputEnglish}%</td>
            </tr>
            <tr>
              <td className="border border-gray-800 px-4 py-2">
                Overall Input/Output in Home Language Outside of School
              </td>
              <td className="border border-gray-800 px-4 py-2">
                {averageInputOutputHomeLanguage}%
              </td>
            </tr>
            <tr>
              <td className="border border-gray-800 px-4 py-2" colSpan="2">
                The parent survey indicated that, during the child's time at outside of school,
                which represents approximately two-thirds of their waking hours, they hear and use
                English {averageInputOutputEnglish}% of the time and hear and use (home language){' '}
                {averageInputOutputHomeLanguage}% of the time.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const TeacherInput = () => {
  const [teacherInput, setTeacherInput] = useState({
    speaking: {
      primaryTeacher: 50,
      student: 50,
      otherPersonnel: 50,
    },
    listening: {
      primaryTeacher: 50,
      student: 50,
      otherPersonnel: 50,
    },
  });

  const handleTeacherInputChange = (event, type, category) => {
    setTeacherInput({
      ...teacherInput,
      [type]: {
        ...teacherInput[type],
        [category]: parseInt(event.target.value),
      },
    });
  };

  const renderTeacherLabel = (value, type) => {
    const speaks =
      teacherInput[type][value] === 0
        ? 'Speaks only in Home Language at School'
        : teacherInput[type][value] === 100
        ? 'Speaks only in English at School'
        : 'Speaks English at School';
    const listens =
      teacherInput[type][value] === 0
        ? 'Listens only in Home Language at School'
        : teacherInput[type][value] === 100
        ? 'Listens only in English at School'
        : 'Listens to English at School';
    return type === 'speaking' ? speaks : listens;
  };

  const calculateTotalHours = (type) => {
    const hoursWithPrimaryTeacher = (15 * teacherInput[type].primaryTeacher) / 100;
    const hoursWithStudents = (15 * teacherInput[type].student) / 100;
    const hoursWithOtherPersonnel = (10 * teacherInput[type].otherPersonnel) / 100;

    const totalHoursInEnglish =
      hoursWithPrimaryTeacher + hoursWithStudents + hoursWithOtherPersonnel;
    const totalPercentageInEnglish = (totalHoursInEnglish / 40) * 100; // Total school hours is 40

    const totalHoursByArea = {
      withPrimaryTeacher: hoursWithPrimaryTeacher.toFixed(2),
      withStudents: hoursWithStudents.toFixed(2),
      withOtherPersonnel: hoursWithOtherPersonnel.toFixed(2),
      overall: totalHoursInEnglish.toFixed(2),
      percentageInEnglish: totalPercentageInEnglish.toFixed(0),
      percentageInHomeLanguage: (100 - totalPercentageInEnglish).toFixed(0),
    };

    return totalHoursByArea;
  };

  const totalSpeakingHours = calculateTotalHours('speaking');
  const totalListeningHours = calculateTotalHours('listening');

  // Calculating averages
  const averageInputOutputEnglish = (
    (parseFloat(totalSpeakingHours.percentageInEnglish) +
      parseFloat(totalListeningHours.percentageInEnglish)) /
    2
  ).toFixed(0);
  const averageInputOutputHomeLanguage = (100 - parseFloat(averageInputOutputEnglish)).toFixed(0);

  const results = {
    input: teacherInput,
    totalSpeakingHours: totalSpeakingHours,
    totalListeningHours: totalListeningHours,
    averageInputOutputEnglish: averageInputOutputEnglish,
    averageInputOutputHomeLanguage: averageInputOutputHomeLanguage,
  };
  return (
    <div>
      <h2 className="text-xl font-semibold mb-2">Teacher Input</h2>
      <div className="md:grid md:grid-cols-2 md:gap-x-10 ">
        <div>
          <h3 className="text-lg font-semibold mb-2">Speaking at School</h3>
          <div>
            <label className="block mb-2">With Primary Teacher(s)</label>
            <input
              type="range"
              min="0"
              max="100"
              value={teacherInput.speaking.primaryTeacher}
              onChange={(e) => handleTeacherInputChange(e, 'speaking', 'primaryTeacher')}
              className="w-full"
            />
            <p className="text-center">
              {teacherInput.speaking.primaryTeacher}% -{' '}
              {renderTeacherLabel('primaryTeacher', 'speaking')}
            </p>
          </div>
          <div>
            <label className="block mb-2">With Student</label>
            <input
              type="range"
              min="0"
              max="100"
              value={teacherInput.speaking.student}
              onChange={(e) => handleTeacherInputChange(e, 'speaking', 'student')}
              className="w-full"
            />
            <p className="text-center">
              {teacherInput.speaking.student}% - {renderTeacherLabel('student', 'speaking')}
            </p>
          </div>
          <div>
            <label className="block mb-2">With Other School Personnel</label>
            <input
              type="range"
              min="0"
              max="100"
              value={teacherInput.speaking.otherPersonnel}
              onChange={(e) => handleTeacherInputChange(e, 'speaking', 'otherPersonnel')}
              className="w-full"
            />
            <p className="text-center">
              {teacherInput.speaking.otherPersonnel}% -{' '}
              {renderTeacherLabel('otherPersonnel', 'speaking')}
            </p>
          </div>

          <table className="w-full mb-4 border border-collapse border-gray-800 hidden">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-800 px-4 py-2">Area</th>
                <th className="border border-gray-800 px-4 py-2">Total Hours in English</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-800 px-4 py-2">With Primary Teacher(s)</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.withPrimaryTeacher} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">With Students</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.withStudents} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">With Other School Personnel</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.withOtherPersonnel} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Total Overall Hours in English</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.overall} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Percentage of Time Speaking in English at School
                </td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.percentageInEnglish}%
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Percentage of Time Speaking in Home Language at School
                </td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.percentageInHomeLanguage}%
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2" colSpan="2">
                  Assumptions: 168 hours in a week, 8 hours per day sleeping, 40 hours at school,
                  and 72 hours outside of school. We assume students spend 3 hours per day with
                  students and with teachers for 5 days a week, bringing a total of 15 hours per
                  week. We assume students spend 2 hours a day with other school personnel for 5
                  days a week, bringing the total to 10 hours per week with other school personnel.
                  In total, the weekly hours combined to be 40 hours.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Listening at School</h3>
          <div>
            <label className="block mb-2">With Primary Teacher(s)</label>
            <input
              type="range"
              min="0"
              max="100"
              value={teacherInput.listening.primaryTeacher}
              onChange={(e) => handleTeacherInputChange(e, 'listening', 'primaryTeacher')}
              className="w-full"
            />
            <p className="text-center">
              {teacherInput.listening.primaryTeacher}% -{' '}
              {renderTeacherLabel('primaryTeacher', 'listening')}
            </p>
          </div>
          <div>
            <label className="block mb-2">With Student</label>
            <input
              type="range"
              min="0"
              max="100"
              value={teacherInput.listening.student}
              onChange={(e) => handleTeacherInputChange(e, 'listening', 'student')}
              className="w-full"
            />
            <p className="text-center">
              {teacherInput.listening.student}% - {renderTeacherLabel('student', 'listening')}
            </p>
          </div>
          <div>
            <label className="block mb-2">With Other School Personnel</label>
            <input
              type="range"
              min="0"
              max="100"
              value={teacherInput.listening.otherPersonnel}
              onChange={(e) => handleTeacherInputChange(e, 'listening', 'otherPersonnel')}
              className="w-full"
            />
            <p className="text-center">
              {teacherInput.listening.otherPersonnel}% -{' '}
              {renderTeacherLabel('otherPersonnel', 'listening')}
            </p>
          </div>

          <table className="w-full mb-4 border border-collapse border-gray-800 hidden">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-800 px-4 py-2">Area</th>
                <th className="border border-gray-800 px-4 py-2">Total Hours in English</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-800 px-4 py-2">With Primary Teacher(s)</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.withPrimaryTeacher} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">With Students</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.withStudents} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">With Other School Personnel</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.withOtherPersonnel} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Total Overall Hours in English</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.overall} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Percentage of Time Listening in English at School
                </td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.percentageInEnglish}%
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Percentage of Time Listening in Home Language at School
                </td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.percentageInHomeLanguage}%
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2" colSpan="2">
                  Assumptions: 168 hours in a week, 8 hours per day sleeping, 40 hours at school,
                  and 72 hours outside of school. We assume students spend 3 hours per day with
                  students and with teachers for 5 days a week, bringing a total of 15 hours per
                  week. We assume students spend 2 hours a day with other school personnel for 5
                  days a week, bringing the total to 10 hours per week with other school personnel.
                  In total, the weekly hours combined to be 40 hours.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Capture Email CTA */}
      <ReportCTA results={results} selectedOption={'teacherInput'} />

      {/* Summary table */}
      <div className="mt-10">
        <h2 className="text-xl font-semibold mb-2">Summary</h2>
        <table className="w-full mb-4 border border-collapse border-gray-800">
          <caption className="caption-top ">
            The teacher survey indicated that, during the child's time at school, which represents
            approximately one-third of their waking hours, their overall input/output in English is{' '}
            {averageInputOutputEnglish}% and their overall input/output in (home language) is{' '}
            {averageInputOutputHomeLanguage}%.
          </caption>

          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-800 px-4 py-2">Category</th>
              <th className="border border-gray-800 px-4 py-2">Average Percentage</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-800 px-4 py-2">
                Overall Input/Output in English at School
              </td>
              <td className="border border-gray-800 px-4 py-2">{averageInputOutputEnglish}%</td>
            </tr>
            <tr>
              <td className="border border-gray-800 px-4 py-2">
                Overall Input/Output in Home Language at School
              </td>
              <td className="border border-gray-800 px-4 py-2">
                {averageInputOutputHomeLanguage}%
              </td>
            </tr>
            <tr>
              <td className="border border-gray-800 px-4 py-2" colSpan="2">
                The teacher survey indicated that, during the child's time at school, which
                represents approximately one-third of their waking hours, their overall input/output
                in English is {averageInputOutputEnglish}% and their overall input/output in (home
                language) is {averageInputOutputHomeLanguage}%.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ReportCTA = ({ results, selectedOption }) => {
  const [email, setEmail] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
    setEmail('');
  };
  return (
    <>
      <div className="relative max-w-lg mx-auto lg:max-w-xl mt-24">
        <LanguageExposureReportEmailFormCallToAction
          results={results}
          selectedOption={selectedOption}
          setModalOpen={setModalOpen}
          email={email}
          setEmail={setEmail}
        />
      </div>
    </>
  );
};
