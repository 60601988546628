import React from 'react';

import { USD_TO_EUR_EXCHANGE_RATE } from './prices';
import { NumberOfEngineers } from '..';
import TIERS from './tiers';

const calculateCentCostInCurrency = ({
  numberOfExaminers,
  perSeatPrices,
  currentlySetCurrency,
}: any) => {
  const usdCentCostPerExaminerPerMonth = perSeatPrices.find(
    (opt) => opt.id === numberOfExaminers
  ).usdCentCostPerExaminerPerMonth;

  let selectedCentCostPerDevPerMonth = usdCentCostPerExaminerPerMonth;
  if (currentlySetCurrency === 'EUR') {
    const eurCentCostPerDevPerMonth = usdCentCostPerExaminerPerMonth * USD_TO_EUR_EXCHANGE_RATE;
    selectedCentCostPerDevPerMonth = Math.round(eurCentCostPerDevPerMonth / 50) * 50;
  }

  return selectedCentCostPerDevPerMonth;
};

const calculateCentCostInFrequency = ({
  numberOfExaminers = 1,
  perSeatPrices,
  currentlySetFrequency,
}: any) => {
  // console.log("kjsdf", perSeatPrices)
  const usdCentCostPerExaminerAnually = perSeatPrices.find(
    (opt) => opt.id === numberOfExaminers
  ).usdCentCostPerExaminerPerMonthNew;
  // console.log("usdCentCostPerExaminerAnually", usdCentCostPerExaminerAnually)

  let selectedCentCostPerDevPerMonth = usdCentCostPerExaminerAnually;
  if (currentlySetFrequency === 'Monthly') {
    const usdCentCostPerExaminerPerMonth = perSeatPrices.find(
      (opt) => opt.id === numberOfExaminers
    ).usdCentCostPerExaminerPerMonth;
    selectedCentCostPerDevPerMonth = usdCentCostPerExaminerPerMonth;
  }

  return selectedCentCostPerDevPerMonth;
};

const calculateCentCostInFrequencyNew = ({
  numberOfExaminers = 1,
  perSeatPrices,
  currentlySetFrequency,
}: any) => {
  // console.log("kjsdf", perSeatPrices)
  const usdCentCostPerExaminerAnually = perSeatPrices.find(
    (opt) => opt.id === numberOfExaminers
  ).usdCentCostPerExaminerAnuallyNew;
  // console.log("usdCentCostPerExaminerAnually", usdCentCostPerExaminerAnually)

  let selectedCentCostPerDevPerMonth = usdCentCostPerExaminerAnually;
  if (currentlySetFrequency === 'Monthly') {
    const usdCentCostPerExaminerPerMonth = perSeatPrices.find(
      (opt) => opt.id === numberOfExaminers
    ).usdCentCostPerExaminerPerMonthNew;
    selectedCentCostPerDevPerMonth = usdCentCostPerExaminerPerMonth;
  }

  return selectedCentCostPerDevPerMonth;
};

const ExaminerTierPriceDisplay = ({
  perSeatPrices,
  numberOfExaminers = 1,
  setNumberOfExaminers,
  currentlySetFrequency,
  isSlpImpact,
}: any) => {
  const centCostPerExaminerPerMonth = calculateCentCostInFrequency({
    numberOfExaminers,
    currentlySetFrequency,
    perSeatPrices,
  });

  const centCostPerExaminerPerMonthNew = calculateCentCostInFrequencyNew({
    numberOfExaminers,
    currentlySetFrequency,
    perSeatPrices,
  });

  // const centCostPerMonth = numberOfEngineers * centCostPerDevPerMonth;
  const centCostPerMonth = numberOfExaminers * centCostPerExaminerPerMonth;

  const centCostPerMonthNew = numberOfExaminers * centCostPerExaminerPerMonthNew;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <>
      <div className="mb-2">
        {currentlySetFrequency === 'Annual' && (
          <span className="text-2xl font-extrabold text-gray-900 line-through transform rotate-45 pr-2">
            {formatter.format((centCostPerMonth * 12) / 100)}
          </span>
        )}
        <span className="text-4xl font-extrabold text-gray-900">
          {currentlySetFrequency === 'Annual'
            ? formatter.format((centCostPerMonthNew * 12) / 100)
            : formatter.format(centCostPerMonthNew / 100)}
        </span>
      </div>

      <div className="mb-4">
        <span className="text-base text-gray-500">
          {' '}
          SLP /{' '}
          {currentlySetFrequency === 'Monthly'
            ? currentlySetFrequency.toLowerCase()
            : 'annually'}{' '}
        </span>{' '}
        <br />
        <br />
        {currentlySetFrequency === 'Annual' && (
          <span className="text-base text-gray-900 bg-green-400 font-bold p-2  rounded-lg align-bottom">
            Save 30%
            {/* {currentlySetFrequency === 'Monthly'
            ? formatter.format(centCostPerMonthNew / 100)
            : formatter.format((centCostPerMonthNew * 12) / 100)}{' '}
          billed{' '}
          {currentlySetFrequency === 'Monthly' ? currentlySetFrequency.toLowerCase() : 'annually'}{' '}
          */}
          </span>
        )}{' '}
        <br />
      </div>

      <div className="mb-5">
        {/* <NumberOfEngineers
          onChange={setNumberOfEngineers}
          value={numberOfEngineers}
          options={perSeatPrices}
          idPrefix="teams-plan-"
        /> */}
      </div>
    </>
  );
};

export default ExaminerTierPriceDisplay;
