import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage, useFormik, FormikProvider, useField } from 'formik';
import React, { useState } from 'react';
import Loader from '../Loader/loader';
import { navigate } from 'gatsby';
import { isBrowser } from '../../utils/isBrowser';

const DemoForm = ({ onSuccess }) => {
  const [submiting, setSubmiting] = useState(false);
  const [response, setResponse] = useState();
  const renderError = (message) => <p className="help is-danger text-red-400 text-xs">{message}</p>;

  const validationSchema = Yup.object({
    firstname: Yup.string().required('First name is a required field'),
    lastname: Yup.string().required('Last name is a required field'),
    company: Yup.string().required('Organization name is a required field'),
    email: Yup.string().email().required('Email is a required field'),
    phone: Yup.string().required('Phone is a required field'),
    // orgType: Yup.string().required('Organization type is a required field'),
    primaryRole: Yup.string().required('Primary role is a required field'),
    totalExaminers: Yup.string().required('Total examiner is a required field'),
    avgCaseload: Yup.string().required('Average caseload is a required field'),
    // message: Yup.string().required('Message is a required field'),
  });
  const initialValues = {
    firstname: '',
    lastname: '',
    company: '',
    email: '',
    phone: '',
    // orgType: '',
    primaryRole: '',
    totalExaminers: '',
    avgCaseload: '',
    message: '',
  };

  const trackDemoRequest = (values) => {
    window.analytics.track('Requested Demo', values);
  };
  //declaration of onSubmit callback
  const onSubmit = async (values) => {
    try {
      // alert(JSON.stringify(values, null, 2));
      var xhr: any = new XMLHttpRequest();
      var url =
        'https://api.hsforms.com/submissions/v3/integration/submit/9473968/7576d87a-d388-4e47-8594-b053c1bb5980';
      var data = {
        // "submittedAt": "1517927174000", // This millisecond timestamp is optional. Update the value from "1517927174000" to avoid an INVALID_TIMESTAMP error.
        fields: [
          {
            objectTypeId: '0-1',
            name: 'email',
            value: values.email,
          },
          {
            objectTypeId: '0-1',
            name: 'firstname',
            value: values.firstname,
          },
          {
            objectTypeId: '0-1',
            name: 'lastname',
            value: values.lastname,
          },
          {
            objectTypeId: '0-1',
            name: 'phone',
            value: values.phone,
          },
          {
            objectTypeId: '0-1',
            name: 'company',
            value: values.company,
          },
          //   {
          //     "objectTypeId": "0-1",
          //     "name": "which_of_the_following_best_represents_the_organization_you_work_for_",
          //     "value": values.orgType
          //   },
          {
            objectTypeId: '0-1',
            name: 'what_s_your_primary_role_on_a_day_to_day_basis_',
            value: values.primaryRole,
          },
          {
            objectTypeId: '0-1',
            name: 'how_many_therapy_providers_are_in_your_team_',
            value: values.totalExaminers,
          },
          {
            objectTypeId: '0-1',
            name: 'at_any_given_time__what_is_the_average_caseload_per_therapy_provider_',
            value: values.avgCaseload,
          },
          {
            objectTypeId: '0-1',
            name: 'message',
            value: values.message,
          },
        ],
        context: {
          // "hutk": "hubspotutk", // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
          pageUri: window.location.href,
          pageName: 'Request Demo',
        },
        legalConsentOptions: {
          consent: {
            // Include this object when GDPR options are enabled
            consentToProcess: true,
            text: 'I agree to allow Example Company to store and process my personal data.',
            communications: [
              {
                value: true,
                subscriptionTypeId: 999,
                text: 'I agree to receive marketing communications from Example Company.',
              },
            ],
          },
        },
      };

      var final_data = JSON.stringify(data);

      xhr.open('POST', url);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          // alert(xhr.responseText)
          // console.log(xhr.responseText)
          // console.log(xhr.responseText?.inlineMessage)
          setResponse(xhr.responseText?.inlineMessage);
          // navigate("/request-demo/thank-you")
          onSuccess();
        } else if (xhr.readyState === 4 && xhr.status === 403) {
          setResponse(xhr.responseText);
          // alert(xhr.responseText)
        } else if (xhr.readyState === 4 && xhr.status === 404) {
          setResponse(xhr.responseText);
          // alert(xhr.responseText)
        }

        setSubmiting(false);
      };

      // Send reqeust
      xhr.send(final_data);

      await fetch('https://track.customer.io/api/v1/forms/02427d82705144e/submit', {
        method: 'POST',
        headers: {
          Authorization: `Basic ${btoa('3110240f4a64c636efea:6ec70ac8f06f9e63f032')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: values }),
      });
    } catch (error) {
      throw new Error('Unable to submit request');
    } finally {
      setSubmiting(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values: any, { setErrors, setSubmitting, resetForm }) => {
      setSubmiting(true);
      try {
        await onSubmit(values);
        trackDemoRequest(values);
      } catch (error) {
      } finally {
        setSubmiting(false);
        resetForm();
      }
    },
  });
  return (
    <>
      <FormikProvider value={formik}>
        <Form className="tracking-wide ">
          <div className="grid grid-cols-6 gap-4 text-stai-1 max-w-2xl mx-auto">
            <div className="md:col-span-3 lg:col-span-3 col-span-6">
              <label className="mb-1 block text-sm text-gray-600" htmlFor="firstname">
                First name *
              </label>
              <div className="">
                <Field
                  name="firstname"
                  id="firstname"
                  type="text"
                  className="w-full rounded-lg border p-3.5 text-sm shadow-sm"
                  // placeholder="First name"
                />
                <ErrorMessage name="firstname" render={renderError} />
              </div>
            </div>
            <div className="md:col-span-3 lg:col-span-3 col-span-6">
              <label className="mb-1 block text-sm text-gray-600" htmlFor="lastname">
                Last name *
              </label>
              <div className="control">
                <Field
                  name="lastname"
                  id="lastname"
                  type="text"
                  className="w-full rounded-lg border p-3.5 text-sm shadow-sm"
                  // placeholder="Last name"
                />
                <ErrorMessage name="lastname" render={renderError} />
              </div>
            </div>
            <div className="col-span-3">
              <label className="mb-1 block text-sm text-gray-600" htmlFor="email">
                Email address *
              </label>
              <div className="control">
                <Field
                  name="email"
                  id="email"
                  type="email"
                  className="w-full rounded-lg border p-3.5 text-sm shadow-sm"
                  // placeholder="Email address"
                />
                <ErrorMessage name="email" render={renderError} />
              </div>
            </div>
            <div className="col-span-3">
              <label className="mb-1 block text-sm text-gray-600" htmlFor="phone">
                Phone number *
              </label>
              <div className="control">
                <Field
                  name="phone"
                  id="phone"
                  type="tel"
                  className="w-full rounded-lg border p-3.5 text-sm shadow-sm"
                  // placeholder="Email address"
                />
                <ErrorMessage name="phone" render={renderError} />
              </div>
            </div>
            <div className="col-span-6">
              <label className="mb-1 block text-sm text-gray-600" htmlFor="company">
                Organizations Name{' '}
                <span className="text-xs">(School district, Clinic, or Company name) *</span>
              </label>
              <div className="control">
                <Field
                  name="company"
                  id="company"
                  type="text"
                  className="w-full rounded-lg border p-3.5 text-sm shadow-sm"
                  // placeholder="Organization name"
                />
                <ErrorMessage name="company" render={renderError} />
              </div>
            </div>
            {/* <div className="md:col-span-6 lg:col-span-6 col-span-6">
          <label className="mb-1 block text-sm text-gray-600" htmlFor="orgType">
          Which of the following best represents the organization you work for?{' '}
          </label>
          <div className="control">

          <div role="group" aria-labelledby="checkbox-group">
            <label className='flex items-center mb-2 gap-x-2'>
              <Field type="checkbox" name="orgType" value="School (K-12)" className="checked:shadow-xl" />
              School (K-12)
            </label>
            <label className='flex items-center mb-2 gap-x-2'>
              <Field type="checkbox" name="orgType" value="Hospital" />
              Hospital
            </label>
            <label className='flex items-center mb-2 gap-x-2'>
              <Field type="checkbox" name="orgType" value="University/College" />
              University/College
            </label>
            <label className='flex items-center mb-2 gap-x-2'>
              <Field type="checkbox" name="orgType" value="Private Practice" />
              Private Practice
            </label>
            <label className='flex items-center mb-2 gap-x-2'>
              <Field type="checkbox" name="orgType" value="Skilled Nursing Facility" />
              Skilled Nursing Facility
            </label>
            <label className='flex items-center mb-2 gap-x-2'>
              <Field type="checkbox" name="orgType" value="Other" />
              Other
            </label>
          </div>
            <ErrorMessage name="orgType" render={renderError} />
          </div>
        </div> */}
            <div className="md:col-span-6 lg:col-span-6 col-span-6">
              <label className="mb-1 block text-sm text-gray-600" htmlFor="primaryRole">
                What’s your primary role on a day-to-day basis?{' '}
              </label>
              <div className="control">
                <Field
                  name="primaryRole"
                  id="primaryRole"
                  type="text"
                  className="w-full rounded-lg border p-3.5 text-sm shadow-sm"
                  // placeholder="Last name"
                />
                <ErrorMessage name="primaryRole" render={renderError} />
              </div>
            </div>
            <div className="md:col-span-6 lg:col-span-6 col-span-6">
              <label className="mb-1 block text-sm text-gray-600" htmlFor="totalExaminers">
                How many evaluation examiners are in your team?{' '}
              </label>
              <div className="control">
                <div role="group" aria-labelledby="my-radio-group">
                  <label className="flex items-center mb-2 gap-x-2">
                    <Field type="radio" name="totalExaminers" value="Just Me" />
                    Just Me
                  </label>
                  <label className="flex items-center mb-2 gap-x-2">
                    <Field type="radio" name="totalExaminers" value="2-5" />
                    2-5
                  </label>
                  <label className="flex items-center mb-2 gap-x-2">
                    <Field type="radio" name="totalExaminers" value="6-10" />
                    6-10
                  </label>
                  <label className="flex items-center mb-2 gap-x-2">
                    <Field type="radio" name="totalExaminers" value="11-15" />
                    11-15
                  </label>
                  <label className="flex items-center mb-2 gap-x-2">
                    <Field type="radio" name="totalExaminers" value="16-20" />
                    16-20
                  </label>
                  <label className="flex items-center mb-2 gap-x-2">
                    <Field type="radio" name="totalExaminers" value="21+" />
                    21+
                  </label>
                </div>
                <ErrorMessage name="totalExaminers" render={renderError} />
              </div>
            </div>
            <div className="md:col-span-6 lg:col-span-6 col-span-6">
              <label className="mb-1 block text-sm text-gray-600" htmlFor="avgCaseload">
                At any given time, what is the average evaluation caseload per examiner?{' '}
              </label>
              <div className="control">
                <div role="group" aria-labelledby="my-radio-group">
                  <label className="flex items-center mb-2 gap-x-2">
                    <Field type="radio" name="avgCaseload" value="0-10" />
                    0-10
                  </label>
                  <label className="flex items-center mb-2 gap-x-2">
                    <Field type="radio" name="avgCaseload" value="11-20" />
                    11-20
                  </label>
                  <label className="flex items-center mb-2 gap-x-2">
                    <Field type="radio" name="avgCaseload" value="21-30" />
                    21-30
                  </label>
                  <label className="flex items-center mb-2 gap-x-2">
                    <Field type="radio" name="avgCaseload" value="31-40" />
                    31-40
                  </label>
                  <label className="flex items-center mb-2 gap-x-2">
                    <Field type="radio" name="avgCaseload" value="41-50" />
                    41-50
                  </label>
                  <label className="flex items-center mb-2 gap-x-2">
                    <Field type="radio" name="avgCaseload" value="50+" />
                    50+
                  </label>
                </div>
                <ErrorMessage name="avgCaseload" render={renderError} />
              </div>
            </div>
            <div className="md:col-span-6 lg:col-span-6 col-span-6">
              <div className="control">
                <MyTextArea
                  label="Please share anything that will help us prepare for our meeting"
                  name="message"
                  rows="6"
                  className="w-full rounded-lg border p-3.5 text-sm shadow-sm"
                  // placeholder="..."
                />
                <ErrorMessage name="message" render={renderError} />
              </div>
            </div>

            <div className="col-span-6 py-2">
              <button
                className={`disabled:cursor-not-allowed disabled:opacity-30 block w-full rounded-lg bg-stai-2 p-2.5 font-bold text-base text-white`}
                type="submit"
                // onClick={() => setLoading(true) }
                disabled={!(formik.isValid && formik.dirty && !submiting)}
              >
                {submiting ? <Loader /> : 'Request Demo'}
              </button>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </>
  );
};

export default DemoForm;

const MyTextArea = ({ label, ...props }: any) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <textarea className="text-area" {...field} {...props} />
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </>
  );
};
