import React from 'react';
import { Button, HelpText } from '..';

import TeamsTierPriceDisplay from './TeamsTierPriceDisplay';
import TeamsTierBenefits from './TeamsTierBenefits';
import TierContainer from './TierContainer';
import TierName from './TierName';
import TierDescription from './TierDescription';
import TIERS from './tiers';
import ExaminerTierBenefits from './ExaminerTierBenefits';
import ExaminerTierPriceDisplay from './ExaminerTierPriceDisplay';
import GrowthTierBenefits from './GrowthTierBenefits';
import ExaminerProTierBenefits from './ExaminerPremiumTierBenefits';
import ExaminerPremiumTierBenefits from './ExaminerPremiumTierBenefits';

// const ctaComponent = (isSlpImpact) => (
//   <Button
//     text={TIERS.examiner.ctaLabel}
//     link={true}
//     color="primary"
//     to={`${isSlpImpact ? TIERS.examiner.ctaLinkToSlpImpact : TIERS.examiner.ctaLinkTo}`}
//     fullWidth
//   />
// );
const ctaComponent = (currentlySetFrequency) => (
  <Button
    text={TIERS.premium.ctaLabel}
    link={true}
    color="primary"
    to={
      currentlySetFrequency === 'Monthly'
        ? `${TIERS.premium.ctaLinkTo}&frequency=Monthly`
        : `${TIERS.premium.ctaLinkTo}&frequency=Annual`
    }
    // to={TIERS.premium.ctaLinkTo}
    onClick={() =>
      window.analytics &&
      window.analytics.track('Selected Plan', {
        plan: TIERS.premium.planId,
        frequency: currentlySetFrequency,
      })
    }
    fullWidth
  />
);
const ExaminerPAYGTier = ({
  ctaHelpText = 'Pay As You Go',
  perUsePrices,
  selectedPlan,
  setSelectedPlan,
}: any) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <button onClick={() => setSelectedPlan('PAYGO')}>
      <TierContainer highlight={selectedPlan === 'PAYGO' ? true : false}>
        <div className="p-6">
          <TierName name={TIERS.paygo.name} />
          <TierDescription description={TIERS.paygo.description} />

          {/* <ExaminerTierPriceDisplay
        perSeatPrices={perSeatPrices}
        numberOfExaminers={numberOfExaminers}
        setNumberOfExaminers={setNumberOfExaminers}
        currentlySetFrequency={currentlySetFrequency}
        isSlpImpact={isSlpImpact}
      /> */}
          <>
            <div className="mb-2">
              <span className="text-4xl font-extrabold text-gray-900">
                {formatter.format(TIERS.paygo.price / 100)}
              </span>
            </div>

            <div className="mb-4">
              <span className="text-base text-gray-500"> per / credit</span> <br />
              <span className="text-base text-gray-900"> </span> <br />
            </div>

            <div className="mb-10">
              {/* <NumberOfEngineers
            onChange={setNumberOfEngineers}
            value={numberOfEngineers}
            options={perSeatPrices}
            idPrefix="teams-plan-"
          /> */}
            </div>
          </>

          <div>
            <div className="mb-1">
              <Button
                text={TIERS.paygo.ctaLabel}
                link={true}
                color="primary"
                to={TIERS.paygo.ctaLinkTo}
                // to={TIERS.premium.ctaLinkTo}
                onClick={() =>
                  window.analytics &&
                  window.analytics.track('Selected Plan', {
                    plan: 'Pay As You Go',
                  })
                }
                fullWidth
              />
            </div>

            <div className="text-center">
              <HelpText message={ctaHelpText} />
            </div>
          </div>
        </div>

        {/* <ExaminerPremiumTierBenefits /> */}
      </TierContainer>
    </button>
  );
};

export default ExaminerPAYGTier;
