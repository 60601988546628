const TIERS = {
  paygo: {
    name: 'Pay As You Go - PAYG',
    minSeats: 30,
    description:
      'Flexible, on-demand access to forms and language samples without a long-term subscription commitment.',
    ctaLabel: 'Get Started For Free',
    ctaLinkTo:
      'https://app.evalubox.com/register?intent=paygo&utm_medium=display&utm_source=pricing&utm_campaign=pricing_to_evalubox_register&utm_content=cta-pricing-paygo-try-for-free',
    planId: 'individual-examiner-professional',
    ctaLinkToSlpImpact: '/request-access/',
    price: 500,
    monthly: '15.00',
    annual: '12.50',
  },
  pro: {
    name: 'Pay As You Go - PAYG',
    minSeats: 30,
    description:
      'Flexible, on-demand access to forms and language samples without a long-term subscription commitment.',
    ctaLabel: 'Get Started',
    ctaLinkTo: '/get-started/?plan=pro',
    planId: 'individual-examiner-professional',
    ctaLinkToSlpImpact: '/request-access/',
    monthly: '15.00',
    annual: '12.50',
  },
  premium: {
    name: 'SLP Pro - Membership',
    minSeats: 120,
    description:
      'Unlimited use of all tools to maximize efficiency in all areas of your evaluation.',
    ctaLabel: 'Get Started For Free',
    planId: 'individual-examiner-premium',
    ctaLinkTo:
      'https://app.evalubox.com/register?intent=slppro&utm_medium=display&utm_source=pricing&utm_campaign=pricing_to_evalubox_register&utm_content=cta-pricing-slppro-try-for-free',
    ctaLinkToSlpImpact: '/slp-impact/request-demo/',
    monthly: '38.70',
    annual: '29.77',
  },
  teams: {
    name: 'Teams',
    minSeats: 150,
    description:
      'Easily assign evaluations, track progress, and monitor workload from an administrative dashboard while giving your team access to a full suite of evaluation tools',
    ctaLabel: 'Request a Demo',
    ctaLinkTo: '/request-demo/',
    ctaLinkToSlpImpact: '/slp-impact/request-demo/',
  },
  forms: {
    name: 'Evalubox Forms',
    minSeats: 150,
    description:
      'For examiners who want to effortlessly gather parent, teacher, and health information in multiple languages to create assessment plans and write reports.',
    ctaLabel: 'Try for free',
    ctaLinkTo:
      'https://app.evalubox.com/register?intent=forms&utm_medium=display&utm_source=forms&utm_campaign=forms_to_evalubox_register&utm_content=cta-pricing-try-for-free',
    ctaLinkToSlpImpact:
      'https://app.evalubox.com/register?intent=forms&utm_medium=display&utm_source=forms&utm_campaign=forms_to_evalubox_register&utm_content=cta-pricing-try-for-free',
    monthly: '12',
    annual: '7',
  },
};

export default TIERS;
