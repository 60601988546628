import React from 'react';
import { Headline, TextLink as Link } from '..';

const FAQ_CONTENT = [
  {
    question: `What are your payment options? `,
    answer: () => (
      <p>
        We offer several payment options: per-use credits, monthly plans, and discounted annual
        plans. Credits let you use tools like the parent-teacher-health forms for one child, while
        monthly and annual plans provide unlimited access to all tools, including the report writer.
        You can easily pay online through our secure system using any major credit card. For school
        districts, we also accept Purchase Orders (POs). Please contact us for details.
      </p>
    ),
  },
  {
    question: `How do credits work? `,
    answer: () => (
      <p>
        Credits are a flexible way to use our tools on a per-client basis. For example, one credit
        allows you to send multiple parent, teacher, and health forms for a single child, with
        translations available in 16 languages. Or, use one credit to collect and analyze a language
        sample, then print the report.
        <br />
        <br />
        Your available credits are displayed at the top of your dashboard, and if you run out,
        you’ll be prompted to purchase more. Please note that credits are non-refundable.
      </p>
    ),
  },
  {
    question: `Do you have a free trial?`,
    answer: () => (
      <p>
        We don’t offer a traditional free trial, but we provide free access to certain features so
        you can explore Evalubox before committing. You can also schedule a short demo to see how
        our tools work and how they can help streamline your evaluation process. Plus, if you decide
        to subscribe, we offer a full refund if you cancel within the first 14 days.
      </p>
    ),
  },
  {
    question: `Can I switch billing frequency?`,
    answer: () => (
      <p>
        Yes, you can switch between monthly and annual billing at any time within Evalubox or just
        contact us via email, and we’ll adjust your plan accordingly.
      </p>
    ),
  },
  {
    question: `Can I pause my account?`,
    answer: () => (
      <p>
        Yes. The realities of our profession mean that some SLPs work only part of the year, take
        leave, or have long transitions between jobs. We get it! Members can pause their account and
        retain access to their documents. To add new students or alter forms and documents, simply
        un-pause your membership.
      </p>
    ),
  },
  {
    question: `What if I don’t have any evaluations right now? `,
    answer: () => (
      <p>
        Some of the most popular features of Evalubox are needed before the evaluation begins:
        organizing all of your upcoming evaluations in one place, sending forms to teachers, nurses,
        and parents, or how about collecting, transcribing, and analyzing a language sample to see
        if an evaluation should even happen in the first place!
        <br />
        <br />
        Take advantage of the time to learn how to dramatically reduce your evaluation time and get
        familiar with all of the tools that will reshape your entire evaluation and data collection
        process. There’s a bonus! We’ve created CEU courses so you get credit for some of the
        learning along the way.
      </p>
    ),
  },

  {
    question: `Can my employer pay for my subscription?`,
    answer: () => (
      <p>
        Yes, your employer can cover your subscription costs. The Team Membership is designed for
        organizations and allows for team management, including adding colleagues and monitoring
        evaluations. If it’s just for you, we can set it up as a Team of one, so you won’t be billed
        separately.
      </p>
    ),
  },
  {
    question: `What’s the difference between SLP Pro and Team Membership?`,
    answer: () => (
      <p>
        SLP Pro gives individual professionals full access to Evalubox, while Team Membership offers
        additional features for team collaboration. Teams can view all evaluations on one dashboard,
        track progress, and reassign evaluations between members. This is ideal for teams where an
        SLP manages incoming evaluations.
        <br />
        <br />
        Payment for Team Membership is typically handled by the district or employer, whereas SLP
        Pro is for individual professionals.
      </p>
    ),
  },
  {
    question: `What is your cancellation policy?`,
    answer: () => (
      <p>You can cancel a subscription at anytime for a full refund within the first 14 days.</p>
    ),
  },
];

const Item = ({ question, answer }) => (
  <div>
    <dt className="font-semibold text-gray-900">{question}</dt>
    <dd className="mt-3 text-gray-500">{answer()}</dd>
  </div>
);

const PricingFAQs = ({
  faqs = FAQ_CONTENT,
  heading = 'Frequently asked questions',
  freeTrial = false,
}: any) => (
  <div className="bg-white">
    <div className="max-w-7xl mx-auto pb-16 px-4  sm:px-6 lg:px-8">
      <div className="max-w-2xl lg:mx-auto lg:text-center">
        <Headline el="h2" size="medium">
          {heading}
        </Headline>
      </div>

      <div className="mt-20 px-8">
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
          {faqs.map((faq) => (
            <Item {...faq} key={faq.question} />
          ))}
        </dl>
      </div>
    </div>
  </div>
);

export default PricingFAQs;
