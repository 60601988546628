import React from 'react';

import { ChevronRightIcon } from '@heroicons/react/solid';
import { TextLink as Link, Lead } from '../components';
// import { ChatIcon, ShieldCheckIcon, AcademicCapIcon, PlayIcon } from '@heroicons/react/outline';

import {
  CodeIcon,
  BookOpenIcon,
  ChatIcon,
  ShieldCheckIcon,
  AcademicCapIcon,
  PlayIcon,
  BookmarkIcon,
  NewspaperIcon,
} from '@heroicons/react/outline';
import AgeCalculatorIcon, {
  AgeCalculatorIconSmall,
  EvaluationIconSmall,
  FileAudioIconSmall,
  FormsIconSmall,
  LibraryIconSmall,
} from './LanguageProficiency/AgeCalculatorIcon';
import { LanguageExposureIconSmall } from './LanguageProficiency/LanguageUseIcon';

const LINKS = [
  // {
  //   title: 'Documentation',
  //   description: 'Set up your Evalubox experience.',
  //   to: '/docs/',
  //   icon: AcademicCapIcon,
  // },
  {
    title: 'Academy',
    description: 'Watch short videos that teach Evalubox concepts.',
    to: 'https://academy.evalubox.com',
    icon: PlayIcon,
  },
  {
    title: 'FAQ',
    description: 'Answers to frequently asked questions.',
    to: '/faqs/',
    icon: AcademicCapIcon,
  },
  {
    title: 'Security',
    description: 'How organizations achieve compliance with Evalubox',
    to: '/security/',
    icon: ShieldCheckIcon,
  },
  //  {
  //   title: 'Case Studies',
  //   description: 'How organisations succeed with Evalubox',
  //   to: '/case-studies/',
  //   icon: ShieldCheckIcon,
  // },
  {
    title: 'Send us an email',
    description: 'Get support or provide feedback.',
    to: 'mailto:admin@evalubox.com?subject=Feedback',
    icon: ChatIcon,
  },
];

const solutions = [
  {
    title: 'Evaluations',
    description: 'Write Comprehensive Speech Language Reports in a Fraction of the Time!',
    to: '/',
    icon: EvaluationIconSmall,
  },
  {
    title: 'Forms',
    description: 'Gather parent, teacher, and health information in multiple languages.',
    to: '/forms/',
    icon: FormsIconSmall,
  },
  {
    title: 'Language Sampling',
    description: 'Record, Transcribe, and Analyze Language Samples in Multiple Languages.',
    to: '/language-sample/',
    icon: FileAudioIconSmall,
  },
  {
    title: 'Chronological Age Calculator',
    description:
      'Calculate full age - broken down into years, months, days, and cumulative age in months.',
    to: '/resources/tools/chronological-age-calculator/',
    icon: AgeCalculatorIconSmall,
  },
  {
    title: 'Language Use and Exposure Calculator',
    description: 'Quickly gather information about the language use of your bilingual clients.',
    to: '/resources/tools/language-use-exposure-calculator/',
    icon: LanguageExposureIconSmall,
  },
  {
    title: 'Speech Therapy Report Templates',
    description: 'Library with popular Speech Therapy Report Templates.',
    to: '/resources/speech-therapy-report-templates/',
    icon: LibraryIconSmall,
  },
];
const learn = [
  {
    name: 'Evalubox Academy',
    description: 'Become an expert quickly with short, searchable, video tutorials.',
    to: 'https://academy.evalubox.com',
    icon: PlayIcon,
  },
  {
    name: 'Free CEU: 7 Reasons Your Reports Take Too Long and How to Shorten the Process',
    description:
      'Streamline Evaluations: 7 Time-Saving Tips for SLPs + Free Resources & Our All-in-One Solution',
    to: 'https://bilinguistics.com/catalog/speech-pathology-continuing-education/video/7-reasons-your-reports-take-too-long/',
    icon: AcademicCapIcon,
  },
  {
    name: 'Free CEU: 5 Ways SLPs are Increasing Their Income and Serving More Children',
    description:
      'Increase Income: Monetize Expertise & Meet Service Needs in Schools, Clinics, & Communities',
    to: 'https://bilinguistics.com/catalog/speech-pathology-continuing-education/free-courses/5-ways-slps-are-increasing-their-income-and-serving-more-children/',
    icon: AcademicCapIcon,
  },
  {
    name: 'Security',
    description:
      'Superior protection, compliance, and back-ups for documents and client information',
    to: '/security/',
    icon: ShieldCheckIcon,
  },
];

const NavigationListItem = ({ link }: any) => (
  <li className="relative py-6 flex items-start space-x-4">
    <div className="flex-shrink-0">
      <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-indigo-50">
        <link.icon className="h-6 w-6 text-indigo-700" aria-hidden="true" />
      </span>
    </div>

    <div className="min-w-0 flex-1">
      <h3 className="text-base font-medium text-gray-900">
        <span className="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
          <Link to={link.to} color="primary">
            <span className="absolute inset-0" aria-hidden="true" />
            {link.title}
          </Link>
        </span>
      </h3>

      <p className="text-base text-gray-500">{link.description}</p>
    </div>

    <div className="flex-shrink-0 self-center">
      <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
    </div>
  </li>
);

const SensibleNavList = ({ lead = `Looking for something specific?`, links = LINKS }) => (
  <div>
    <div className="mb-2 text-center">
      <Lead>{lead}</Lead>
    </div>

    <ul className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
      {solutions.map((link, linkIdx) => (
        <NavigationListItem link={link} key={linkIdx} />
      ))}
    </ul>
  </div>
);

export default SensibleNavList;
