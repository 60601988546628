import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import {
  CodeIcon,
  BookOpenIcon,
  ShieldCheckIcon,
  AcademicCapIcon,
  SparklesIcon,
  DesktopComputerIcon,
  NewspaperIcon,
  BriefcaseIcon,
  PlayIcon,
  BookmarkIcon,
} from '@heroicons/react/outline';

import MobileFreeTrialButton from './MobileFreeTrialButton';
import MobileDropdownNavItem from './MobileDropdownNavItem';
import DrawerMenuHeader from './DrawerMenuHeader';
import {
  AgeCalculatorIconSmall,
  EvaluationIconSmall,
  FileAudioIconSmall,
  FormsIconSmall,
  LibraryIconSmall,
} from '../LanguageProficiency/AgeCalculatorIcon';
import { LanguageExposureIconSmall } from '../LanguageProficiency/LanguageUseIcon';

const subItems = [
  {
    name: 'Evaluations',
    description: 'Write Comprehensive Speech Language Reports in a Fraction of the Time!',
    to: '/',
    icon: EvaluationIconSmall,
  },
  {
    name: 'Forms',
    description: 'Gather parent, teacher, and health information in multiple languages.',
    to: '/forms/',
    icon: FormsIconSmall,
  },
  {
    name: 'Language Sampling',
    description: 'Record, Transcribe, and Analyze Language Samples in Multiple Languages.',
    to: '/language-sample/',
    icon: FileAudioIconSmall,
  },
  {
    name: 'Chronological Age Calculator',
    description:
      'Calculate full age - broken down into years, months, days, and cumulative age in months.',
    to: '/resources/tools/chronological-age-calculator/',
    icon: AgeCalculatorIconSmall,
  },
  {
    name: 'Language Use and Exposure Calculator',
    description: 'Quickly gather information about the language use of your bilingual clients.',
    to: '/resources/tools/language-use-exposure-calculator/',
    icon: LanguageExposureIconSmall,
  },
  {
    name: 'Speech Therapy Report Templates',
    description: 'Library with popular Speech Therapy Report Templates.',
    to: '/resources/speech-therapy-report-templates/',
    icon: LibraryIconSmall,
  },
  {
    name: 'Pricing',
    to: '/pricing/',
    icon: SparklesIcon,
  },
  {
    name: 'Request Demo',
    to: '/request-demo/',
    icon: BriefcaseIcon,
  },
];

const DrawerMenu = () => (
  <Transition
    as={Fragment}
    enter="duration-200 ease-out"
    enterFrom="opacity-0 scale-95"
    enterTo="opacity-100 scale-100"
    leave="duration-100 ease-in"
    leaveFrom="opacity-100 scale-100"
    leaveTo="opacity-0 scale-95"
  >
    <Popover.Panel
      focus
      className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
    >
      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
        <div className="pt-5 pb-6 px-5">
          <DrawerMenuHeader />

          <div className="mt-6">
            <nav className="grid gap-y-8">
              {subItems.map((item) => (
                <MobileDropdownNavItem item={item} key={item.name} />
              ))}
            </nav>
          </div>
        </div>

        <div className="py-6 px-5 space-y-6">
          <div>
            <MobileFreeTrialButton />
          </div>
        </div>
      </div>
    </Popover.Panel>
  </Transition>
);

export default DrawerMenu;
